  /* form {
    margin-bottom: 10px;
  } */
  
  .add-coupon input[type="text"], .edit-coupon input[type="text"] {
    padding: 5px;
    margin-right: 10px;
    background: #302f42;
    color: #ffffff;
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    border: none;
  }
  
  /* ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  
  li {
    margin-bottom: 10px;
    width: 100%;
  } */
  .add-coupon{
    margin: auto;
    width: 80%;
    background-color: rgb(6, 0, 27);
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .coupon-list{
    margin: auto;
    width: 80%;
    margin-top: 10px;
    /* margin-left: 0!important; */
    padding: 20px;
    background-color: rgb(11, 11, 39);
    border-radius: 5px;
    align-items: baseline;
    justify-content: space-between;
    display: flex;
  }

  .coupon-item {
    /* margin-right: 10px; */
    color: #ffffff;
  }
  
  .todo-ul-li ul{
    margin-left: 0!important;
  }

  .add-coupon input[type="text"],
  .coupon-item,
  button {
    display: inline-block;
  }
  
  .form-ul{
    padding-left: 0!important;
  }

  .add-coupon input{
    width: 400px;
  }

  /* button + button {
    margin-left: 10px;
  } */
  
  .edit-coupon input{
    display: inline-block;
  }

  .edit-coupon{
    width: 80%;
    background-color: rgb(11, 11, 39);
    padding: 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 837px){
    .add-coupon input{
      width: 60%;
    }
  }

  @media (max-width: 650px){
    .add-coupon input{
      width: 100%;
      margin-right: 0!important;
    }

    .add-coupon{
      flex-direction: column;
      gap: 20px;
      padding: 15px;
    }

    .add-coupon button{
      width: 200px;
      margin: auto;
    }

    

    .edit-coupon{
      width: 100%;
      padding: 10px;
    }

    .edit-coupon input{
      font-size: 14px;
      margin-right: 0!important;
    }
  }

  @media (max-width: 427px){
    .edit-coupon{
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

    .coupon-list{
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

  }

  @media (max-width: 373px){
    .add-coupon button{
      width: 100%;
    }
  }