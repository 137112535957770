/* recent block css start */
.recent-winner-inner-wrapper{
    gap: 33px;
}

.recent-winner-top{
    gap: 13px;
}
.recent-winner-top .recent-user {
    width: 38px;
    height: 38px;
    position: relative;
    background-color: #ffffff;
    border-radius: 100%;
}
.recent-block {
    background-color: #0f1d3b;
    border-radius: 5px;
    padding: 10px;
}
.recent-winner-inner-wrapper .recent-block:nth-of-type(even){
background-color: #0A0D19;
}
.recent-winner-inner-wrapper.new-wrapper .recent-block:nth-of-type(odd){
background-color: #0A0D19;
}
.recent-winner-inner-wrapper.new-wrapper .recent-block:nth-of-type(even){
background-color: #0f1d3b;
}
.recent-winner-top .recent-user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    /* position: absolute; */
    /* top: 0;
    left: 0;
    right: 0; */
}
.recent-winner-bottom h3 {
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
}
.recent-winner-bottom {
    color: #ffffff;
}
.recent-user-vs img{
    width: 43px;
    height: 37px;
}
.recent-winners-loading{
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    width: 80px;
}
.challenge-desc{
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    text-align: right;
}
.divider-vs{
    background: #0B1124;border-radius: 0.625rem;
    max-width: 7.5rem;
    width: 100%;
    height: 0.0625rem;
    margin: 9px auto;
}
.recent-winner-bottom h3, .recent-winner-bottom span{
    color: #FFF;
    font-family: Roboto;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.04875rem;
}
.recent-winner-bottom span{
    margin-left: 5px;
}
.recent-winner-bottom div{
    color: #1DCADC;
    font-family: Bungee;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    border-radius: 0.625rem;
    background: #0B1124;
    border-radius: 10px;
    padding: 0 6px;
    margin-left: 7px;
    height: 31px;
    width: fit-content;
}
.new-title{
    color: #FFF;
    font-family: Bungee;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
    margin-bottom: 36px;
}
.new-title span{
    color: #C4C4C4;
    text-align: center;
    font-family: Bungee;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
    position: relative;
    margin-left: 36px;
}
.new-title span::before{
    content: '';
    background-color: #C4C4C4;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 12px;
    left: -20px;
}
.top-earner{
    margin-top: 1px;
    padding-top: 70px;
}
.recent-block h3{
    color: #FFF;
    font-family: Roboto;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.04875rem;
    margin: 0;
}
.add-white-space{
    margin-top: 5px;
}
@media(max-width:768.98px){
    .recent-winner-inner-wrapper{
        gap: 24px;
    }
}
@media(max-width:767.98px){
    .recent-winner-inner-wrapper{
        gap: 24px;
    }
    .new-title{
        flex-direction: column;
    }
    .new-title span{
        margin: 0;
        margin-top: 24px;
    }
    .new-title span::before {
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}
/* recent block css end */