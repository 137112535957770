.signup_container {
	width: 100%;
	/* height: 80vh; */
	min-height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 25px 0;
	position: relative;	
	overflow: hidden;
}
.signup_header{
	text-align: center;
	font-family: "Bungee", sans-serif;
	font-size: 140%;
	margin-bottom: 25px;
	margin-left: 5px;
	margin-right: 5px;
	text-shadow: -1px -1px 0 white, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.signup_step_container{
	display: block;
	width: 100%;
	position: relative;
}
.signup_step_header{
	display: block;
	width: 100%;
	margin-left: 4%;
	background-color: white;
	margin-bottom: 15px;
	box-shadow: #696969 -5px 5px 5px;
	border-radius: 10px;
	border: black 1px solid;
}
.signup_step_label{
	position: relative;
	display: block;
	align-items: center;
	justify-content: center;
	margin-left: 5%;
	font-size: 150%;
	font-family: "Bungee", sans-serif;
	color: black;
}
.signup_step_icon{
	justify-self: right;
}
.signup_step_content_container{
	position: relative;
	display: block;
	margin-left: 8%;
	bottom: 20px;
	width: 100%;
	overflow: clip;
	background-color: white;
	box-shadow: #696969 -5px 7px 4px;
}
.signup_step_content{
	margin-left: 5%;
	margin-right: 12%;
	max-height: 0;
	position: relative;
	transition: max-height 0.4s ease;
}
.selected_step{
	padding-top: 10px;
	padding-bottom: 15px;
	max-height: 450px;
}
.ow_header_info{
	font-weight: 300;
	color: black;
	font-size: 15px;
	font-family: 'Bungee';
}
.ow_text_info{
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: 5px;
}
.create_account_button{
	background: #1b7bbb;
	color: white;
	border: none;
	outline: none;
	padding: 10px 10px;
	margin-top: 30px;
	border-radius: 6px;
	margin-left: 55px;
	width: 400px;
	font-weight: normal;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.create_account_button:disabled{
	cursor: not-allowed;
	background: #536069;
}
.create_account_button:disabled:hover{
	background: #536069;
}
.create_account_button:hover, .create_account_button:active{
	background: #155d8d;
}

.avatar{
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	margin-left: 10px;
}

.file {
	opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file_input label {
  display: block;
  position: relative;
  width: 370px;
  height: 45px;
  border-radius: 10px;
  /* background: linear-gradient(40deg,#ff6ec4,#7873f5); */
  background-color:#1b193c;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: transform .2s ease-out;
  font-size: 20px;
}

.file_name {
	position: absolute;
	bottom: -35px;
	left: 10px;
  font-size: 20px;
  color: #555;
}

/* input:hover + label,
input:focus + label {
  transform: scale(1.02);
} */

/* Adding an outline to the label on focus */
/* input:focus + label {
	outline: 1px solid #000;
	outline: -webkit-focus-ring-color auto 2px;
} */

.signup_form_container {
	width: 1400px;
	display: flex;
	height: 100vh;
	background-color: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
	0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.country_code{
	position: absolute;
	font-size: 14px;
	top: 24px;
	left: 14px;
	font-weight: bold;
}
.left {
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	background: #0f0c29;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
	background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
	/* background-image: url("../images/logo-2.png");
	background-position: center;
	background-size: cover; */
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	height: 100vh;
}

.left_inner{
	display: flex;
	justify-content: center;
	margin: auto 0;
}

.left_inner img{
	width: 400px;
}

.left h1 {
	margin-top: 0;
	color: white;
	font-size: 35px;
	align-self: center;
}

.right {
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #ffffff;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	align-self: center;
    font-size: 35px;
    margin-top: 0;
    font-family: 'Audiowide';
	height: 100vh;
}

.right_inner{
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	font-size: 14px;
	font-family: "Roboto", sans-serif;
}

.form_field {
	display: flex;
	width: 100%;
	gap: 10px;
  }

.login_link{
	font-size: 14px;
	padding: 0;
	border: none;
    outline: none;
    background: none;
    margin-left: 8px;
    color: #0065ea;
	transition: all 0.2s ease-in-out;
}

.login_link:hover,
.login_link:active {
  color: rgb(0, 67, 154);
  box-shadow: 0 1px 0 rgb(0, 67, 154);
}

.form_container {
	display: block;
	flex-direction: column;
	align-items: center;
}

.form_container h2 {
	margin-top: 0;
	font-family: "Bungee", sans-serif;
	margin-bottom: 10px;
	color: #1b193c;
}

.input {
	outline: none;
	border: none;
	width: 370px;
	padding: 12px;
	border-radius: 10px;
	background-color: #dee2e6;
	margin: 5px 0;
	font-size: 14px;
	color: #1b193c;
}

.input_wrapper {
	position: relative;
}

.required_indicator {
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translateY(-70%);
	color: red;
	font-size: 20px;
}

.error_msg {
	width: 370px;
	padding: 15px;
	margin: 7px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.register_btn{
	background:var(--Grrr,linear-gradient(245deg,#1dcadc 0,#582c7f 100%));
	color: white;
	border: none;
	outline: none;
	padding: 10px 0;
	margin-top: 30px;
	border-radius: 6px;
	width: 120px;
	font-weight: normal;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.register_btn:hover, .register_btn:active{
	background: var(--Grrr,linear-gradient(245deg,#1dcadc 0,#582c7f 100%)) ;
}

.white_btn,
.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.green_btn {
	background:#4A6BBC;
	color: white;
	margin: 10px;
}

.register_form{
	font-family: "Bungee";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-self: center;
    color: #1b193c;
}
.register_ow_header{
	font-family: "Bungee";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	align-self: center;
	color: #1b193c;
}
.register_ow_field{
	display: inline-block;
	gap: 10px;
	outline: none;
	border: none;
	padding: 8px;
	border-radius: 10px;
	background-color: #dee2e6;
	font-size: 14px;
	color: #1b193c;
}
.register_ow_label{
	font-family: "Bungee";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-self: center;
	text-align: right;
	margin-right: 10px;
    color: #6c6c83;
}
.register_ow_value{
	font-family: "Bungee";
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    align-self: center;
	text-align: right;
    color: #6c699e;
}
.register_username_info{
	font-family: "Bungee";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 10px;
	padding-left: 142px;
	height: 0px;
	display: absolute;
}
.register_username_info.success{
	color: green;
}
.register_username_info.fail{
	color: red;
}

@media only screen and (max-width: 1078px){
	.signup_form_container {
		flex-direction: column;
		width: 100vw;
		height: fit-content;
		padding: 10px 0;
	}
	.left{
		display: none;
		/* flex: 1; */
	}
	.right {
		width: 80vw;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.form_container {
		width: 70%
	}
	.form_field .input_wrapper input{
		width: 100%;
	}
	.add-full-width{
		max-width: 100% !important;
	}
}

@media only screen and (max-width: 855px) {
	.form_container {
		width: 80%
	}
}

@media only screen and (max-width: 778px) {
		.form_container {
			font-size: 30px;
			/* font-family: 'Audiowide'; */
		}
		.signup_form_container {
			width: 100%;
		}
		.right {
			width: 100%;
		}
		.country_code{
			top:18px
		}
}


@media only screen and (max-width: 667px) {
  
    .left h1 {
        font-size: 30px;
		margin-top: 40px;
    }
	.form_container {
		width: 95%
	}
   .form_container h1 {
        font-size: 30px;
	
    }
	.signup_form_container{
		padding: 10px 10px;
	}
}

@media only screen and (max-width: 558px){

	.form_container h1{
		font-size: 30px;
		line-height: 36px;
	}
	.input{
		width: 300px;
		padding: 11px;
		font-size: 13px;
	}
	.file_input label{
		width: 300px;
		font-size: 16px;
	}
	.register_btn{
		font-size: 13px !important;
		padding: 10px 0;
		width: 90px;
	}
	.avatar{
		width: 2rem;
		height: 2rem;
	}
}

@media (max-width: 465px){
	.form_field {
		display: flex;
		flex-direction: column;
		width: auto;
		gap:0px;
	  }
	.register_form{
	  align-self: start;
	  margin-top: 10px;
	  
	}
}

@media (max-width: 425px){
	.form_container h1{
		font-size: 25px;
	}
	.input, .file_input label{
		width: 250px;
	}
	.login_link{
		font-size: 13px !important;
	}
}

/* add new css on 14-08-12 */
.form_field label{
	min-width: 130px;
}
.form_field .input_wrapper{
	/* max-width: calc(100% - 120px); */
	width: 100%;
}
@media(max-width:1024.98px){
	.form_field .input_wrapper input{
		width: 100%;
	}
	.add-full-width{
		max-width: 100% !important;
	}
}
@media(max-width:465.98px){
	.form_field{
		width: 100% !important;
	}
}