.how-to-play {
    background-color: #0B1124;
    padding: 100px 0px 60px 0;
    min-height:100vh;
}

.how-container {
    max-width: 1000px;
    margin: auto;
}

.instructions-heading{
    background-color: #06001b;
    border-radius: 5px;
    color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 20px;
}

.instructions-content{
    /* text-align: center; */
    color: white;
}

.instructions-content p{
    margin-left: 2rem;
}
.content-list li{
    list-style-type: decimal !important;
    padding-top: 15px !important;
}

.content-unordered-list{
    padding-left: 17px !important;
}

.content-unordered-list li{
    list-style: none;
    
}
.content-unordered-list li::before{
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    transform: rotate(45deg);
    background-color: #fff;
}

/* .instructions-content img{
    max-height: 340px;
    height: 100%;
    max-width: 680px;
    width: 100%;
} */

/* .how-challenge {
    display: flex; 
    gap: 20px;  
} */
.center-gif{
    display: flex; 
    flex-direction: column;
    align-items: center;
}
div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu div {
    display: inline-block;
    /* color: white; */
    text-align: center;
    padding: 14px;
  }

#instructions-head::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	/* background-color: #F5F5F5; */
}

#instructions-head::-webkit-scrollbar
{
	width: 12px;
	/* background-color: #F5F5F5; */
}

#instructions-head::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.instructions-two-heading{
    background-color: #06001b;
    color: white;
    padding: 10px;
}

.how-to-play-btn{
    position: absolute; 
    background-color: #F5F5F5;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.how-to-play-btn i {
    color:black;
    margin-left: 3px;
}

@media(max-width:620.98px){
    .how-to-play img{
        max-width: 500px;
        width: 100%;
        max-height: 500px;
        height: 100%;
    }
}

@media (max-width:778.98px) {
    .how-to-play{
        padding: 100px 0px 0px 0;
    }
}