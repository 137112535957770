.partner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 7%;
    align-items: center;
  }
  .partner-container > div img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .partner-container > div {
    text-align: center;
    max-width: 25%;
    flex: 0 0 25%;
    position: relative;
    height: 65px;
    margin-top: 33px;
  }
  .add-start-space{
    margin-top: 100px;
  }
  .new-title-1{
    color: #FFF;
    font-family: Bungee;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
    margin-bottom: 0;
  }
  .new-title-1 span{
    color: #1DCADC; 
     font-family: Bungee;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
    margin-bottom: 0;
  }
  .sub-title-1{
    color: #C4C4C4;
    text-align: center;
    font-family: Bungee;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
  }
  .sub-title-1 span{
    color: #1DCADC;
    font-family: Bungee;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
  }
  @media (max-width: 1280px){
    .partner-container > div img {
      padding: 12px;
   }
  }
  @media (max-width: 991px){
    .partner-container{
      padding: 0;
    }
    .add-start-space{
      margin-top: 50px;
    }
  }
  @media (max-width: 600px){
    .partner-container > div {
      width: 100%;
      text-align: center;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }