.trans-table{
    padding: 80px;
}
.trans-logs{
    min-height: 100vh;
}
.user-no-trans-log-msg{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    padding: 10px;
    top: 30%;
    position: relative;
}
@media (max-width:992px) {  
    .trans-table{
        padding: 50px 0px;
    }
 }