.duelstatus-sec{
    background-color: #121127;
    padding: 40px 0px;
    width: 98vw;
    min-height: 80vh;
    display: flex;
    justify-content: center;
}
.duelstatus-user-title{
    font-family: Audiowide;
  font-size: 44px;
  font-weight: 100;
  line-height: 56px;
  letter-spacing: 0em;
  margin-bottom: 74px;
  text-align: center;
  color: white;
}
.status-table{
    padding: 100px;
}
.duel-status{
    min-height: 100vh;
}
.duelsatus-table th {
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 500;
}
.duelsatus-table table.table.table-bordered {
    background: #201748;
    padding: 20px;

}
.user-title {
    text-align: center;
}

td{
    color:white
}

tr{
    text-align: center;
}

.user-title h1 {
    color: #fff;
    font-weight: 500;
    font-size: 35px;
    line-height: 44px;
    font-family: 'Audiowide', cursive;
}
.user-title p {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    
}
.duelsatus-table {
    max-width: 75%;
    margin: auto;
}
.duelsatus-table .table>thead {
    height: 50px !important;
}

.link-btn{
    color:white;
    background-color:#4a6bbc ;
}
.avatar-name{
    display: flex;
    align-items: center;
}
.avatar-name-1{
    padding-left: 20px;
}   
.no-records-found{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    background-color: #0b0b27;
    font-family: 'Bungee';
}
.no-records-found-home{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    background-color: #0b0b27;
    font-family: 'Bungee';
    flex-direction: column;
    gap: 15px;
}
.duelstatus-modal-btns-align{
    display: flex;
    gap: 20px;
    margin: 0 auto;
}
.duelstatus-modal-checkbox-text{
    font-weight: bold;
    margin-right: 10px;
}
.duel-status-checkbox-msg{
    margin-top: -8px;
    margin-bottom: 0px;
    font-size: 13px;
    color: red;
}
/* responsive */

@media (max-width:992px) {  
    .status-table{
        padding: 50px 20px;
    }
    .nav-menu {
        padding-left: 0px;
    }
    .navbar-light .navbar-nav .nav-link {
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        padding: 0px !important;
    }
 }

 @media (max-width:778px) {  
    .user-title {
        text-align: center;
    }
    .user-title h1 {
        font-size: 30px;
        line-height: 44px;
    }
    .duelsatus-table {
        max-width: 100%;
        margin: auto;
        margin-bottom: 10px !important;
        overflow-y: scroll;
    }
 }


 @media (max-width:667px) {  
    .section-title > .head-duel-system{
        font-size: 25px !important;
    }
    .duelsatus-table th {
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        line-height: 18px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-weight: 500;
    }
    .no-records-found{
        font-size: 13px;
    }
    .no-records-found-home{
        font-size: 13px;
    }
    .no-records-found-home .btn {
        font-size: 18px;
    }
}
@media (width < 400px){
    .winneritem1:nth-child(1){
        height: 210px !important
    }
}

.duelstatus-modal-content{
    max-width: 500px;
    width: 100%;
    max-height:320px;
    height: 100%;
    text-align: center;
}
.duelstatus-confirmation-modal-content{
    max-width: 500px;
    width: 100%;
    height: 100%;
    text-align: center;
    max-height:240px;
}
.duelstatus-modal-text {
    font-family: 'Bungee';
    font-size: 15px;
}
@media(max-width:768.98px){
    .duelstatus-modal-content{
        max-height:320px;
    }
}