.service-charge-text input {
    margin-left: 10px;
    text-align: center;
    background: #302f42;
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    color: white;
    border: none;
}

.service-charge-text button {
    margin-left: auto;
    text-align: center;
}

.service-charge-text {
    margin: 5%;
    display: flex;
    align-items: center;
    padding: 40px;
    background-color: rgb(6, 0, 27);
    flex-direction: row;
  justify-content: center;
  }

  .admin-service{
    width: 100%;
  }

  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-container {
    display: flex;
    align-items: center;
  }
  
  .button-container {
    display: flex;
  }
  @media (max-width: 880px){
    .input-row{
        flex-direction: column;
        gap: 20px;
    }
    .handlebtns{
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
  }

  @media (max-width: 624px){
    .service-charge-text input {
        width: 100px;
    }

    .service-charge-text {
        margin: 2%;
    }
  }
