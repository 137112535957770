.user-title{
  font-family: Audiowide;
  font-size: 44px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  margin-bottom: 74px;
  margin-top: 100px;
}

.profile-body {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.profile-section-1 {
  /* background-image: url(../images/test.jpg);
      */
  /* opacity: 0.5; */
  background-repeat: no-repeat;
  background-size: cover;
  background: #f7f7f81e;
 /* background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);*/
 /* background: linear-gradient(to right, #24243e, #302b63, #0f0c29);*/
  border-radius: 5px;
  margin-top: 64px;
  border: 1px solid rgba(92, 91, 91, 0.829);
}
.profile-section-2 {
  width: 67%;
  margin-top: -60px;
}
.avatar-dp {
  justify-content: center;
  display: flex;
}
.profile-avatar {
  margin-top: -69px;
  width: 110px;
  height: 110px;
}
.section-1-data {
  display: flex;
  flex-direction: column;
}
.section-1-userdata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 450px;
  margin: 0 auto;
}
.section-1-name {
  color: white;
  font-size: 21px;
  line-height: 25px;
  font-family: "Bungee";
  margin: 0 auto;
}
.section-1-subdata {
font-family: Roboto;
font-size: 15px;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
}
.section-1-grey {
  color: rgb(180, 180, 180);
  font-family: Roboto;
font-size: 11px;
font-weight: 700;
line-height: 13px;
letter-spacing: 0em;
margin-top: 4px;
margin-bottom: 15px;
}

.section-1-account-status {
  display: flex;
  gap: 32px;
  margin-top: 28px;
  margin-bottom: 27px;
}

.section-1-status {
  font-family: "Audiowide";
}

.section-2-heading {
  display: flex;
  /* overflow: auto; */
  justify-content: center;
}
.section-2-heading button{
  padding: 0 20px!important;
  white-space: nowrap;
  font-family: "Bungee" !important;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 10px;
}
.section-2-heading button.active-btn-link{
  background: var(--Grrr, linear-gradient(245deg, #1DCADC 0%, #582C7F 100%));
}
.button-link {
  font-size: 18px !important;
  /* font-weight: 500; */
  color: rgb(180, 180, 180);
}
.section-1-subdata .btn-space{
  border-radius: 5px !important;
  background-color: #4a6bbc !important;
  color: white !important;
  margin-top: 31px;
  line-height: 33px !important;
  padding: 0 !important; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 123px !important;
  font-family: Roboto !important; 
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.section-1-status div{
line-height: 14px;
letter-spacing: 0.05em;
text-align: center;
margin-bottom: 4px;
font-family: 'Bungee';
}
.section-1-status span{
  display: block;
  font-family: Audiowide;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
}
.section-2-body {
  padding: 30px;
}
.profile-nft-images {
  width: 200px !important;
  height: 280px !important;
  max-width: 350px !important;
  border-radius: 20px;
  margin-top: 10px;
}
.profile-nft-images:hover {
  transform: scale(1.05);
  transition: ease-in-out 0.5s;
}
.nft-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 35px;
  padding: 10px 10px;
  row-gap: 24px;
}
.data-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.save-profile {
  margin-top: 10px;
  padding: 5px 50px;
  border-radius: 20px;
}
.challenge-btn {
  border-radius: 20px;
}
.profile-input {
  outline: none;
  border: none;
  max-width: 370px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #dee2e6;
  margin: 5px 0;
  font-size: 14px;
}
.header-nft-images {
  color: white;
  font-size: 25px;
  font-family: "Bungee";
  display: flex;
  justify-content: center;
  align-items: center;
}
.images-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-title {
  color: white;
  font-size: 40px;
  font-family: "Bungee";
}
.file_input label {
  display: block;
  position: relative;
  width: 365px;
  height: 50px;
  border-radius: 10px;
  /* background: linear-gradient(40deg,#ff6ec4,#7873f5); */
  background-color: #1b193c;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-top: 35px;
  transition: transform 0.2s ease-out;
}
.user-edit-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-form{
  align-self: start;
  margin-top: 5px;
}

.user-edit-form input{
  margin-top: 6px !important;
}
.user-edit-form .country-select,.user-edit-form .state-select{
  margin-top: 6px !important;
}
.country-input{
    margin: 5px 0;
}
.section-1-name .avatar-section {
  padding-left: 10px;
}

.section-2-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 552px;
  width: 100%;
  height: 72px;
  color: #fff;
  box-sizing: border-box;
  background: #494859;
  border: 2px solid #4f4f4f;
  border-radius: 5px;
  gap: 28px;
  cursor: pointer;
}
.section-2-options:hover {
  background: #3e3d4b;
  border: 2px solid #414141;
  transition: ease-in-out 0.1s;
}
.app-icon {
  width: 56px;
  /* height: 60px; */
  padding-left: 10px;
}

.choose-icon {
  width: 40px;
  height: 40px;
}

/* Align choose icon to end */

.app-name {
  flex-grow: 1;
  font-family: "bungee";
font-size: 24px;
font-weight: 400;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
}

.section-2-connect{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.section-2-connect a{
  width: 100%;
}
/* .choose-icon {
  margin-left: 20px;
} */

.payment {
  display: flex;
  gap: 100px;
}

.deposit-sec-1 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.won-money {
  width: 264px;
  background-color: #1e1d32;
  box-shadow: 0px 2px 10px rgba(3, 3, 3, 0.09);
  border-radius: 15px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-input-money input {
  background: #1e1d32;
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  /* font-size: 56px; */
  line-height: 83px;
  color: #1DCADC;
  border-style: none;
  width: 80%;
  text-align: left;
}

.profile-input-money {
  display: flex;
  align-items: center;
  width: 100%;
}

.profile-dollar-sign {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  /* font-size: 56px; */
  line-height: 83px;
  color: #1DCADC;
  background: #1e1d32;
  border-style: none;
  width: 20%;
  text-align: right;
}

.profile-input-money input[type="number"] {
  border: none;
  outline: none;
  flex: 1;
  padding: 0px;
}

.won-fund {
  letter-spacing: 0.115em;
  color: #b4b4b4;
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.11em;
}
.tranfer-options {
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  gap: 14px;
}

.tranfer-options button {
  max-width: 259px;
  width: 100%;
  height: 51px;
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 31px;
  color: #ffffff;
}

.transfer-icon {
  width: 46px;
  height: 30px;
  padding-left: auto !important;
}

.transfer-gap{
  gap: 20px;
}
.transfer-2-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 389px;
  width: 100%;
  height: 71px;
  color: #fff;
  box-sizing: border-box;
  background: #494859;
  border: 2px solid #4f4f4f;
  border-radius: 5px;
  gap: 28px;
}

.application-name {
  margin-left: 0%;
}

.textbox{
  width: 100%;
  height: 42px;
  background: #302f42;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  border: none;
  margin-right: 8px;
  padding: 10px;
  margin-top: 10px;
}

input[type="text"][disabled] {
  background-color:hsl(243 17% 22% / 0.3);
}

.textbox-save{
  max-width: 552px;
  width: 100%;
  display: flex;
}

.input_wrapper {
  position: relative;
  }

.required_indicator {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-85%);
  color: red;
  font-size: 20px;
  }

.profile-modal-content{
  width: 600px;
}

.cashout-profile-form {
  display: grid;
  place-content: center right;
  padding-top: 5px
}

.cashout-input-right {
padding-right: 45px;
}

.error-message {
color: red;
font-size: 16px;
}

.cashout-btn {
height: 45px;
width: 204px;
background: rgb(14, 240, 108) !important;
border-radius: 5px;
font-family: "Audiowide";
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: #ffffff;
border: none;
text-transform: uppercase;
font-size: 20px !important;
}
.section-1-subdata {
  margin-top: 15px;
}

.select-field{
  max-width: 100%;
  width: 100%;
  /* padding: 15px; */
  border-radius: 10px;
  background-color: #dee2e6;
  margin: 5px 0;
  font-size: 14px;
}
.select-field > div{
  background-color: #dee2e6;
  border-radius: 10px;
  min-height: 52px;
  text-align: start;
}
.file_input .input-file-wrapper{
  max-width: 370px !important;
  width: 100% !important;
}
.user-edit-form .row , .user-edit-form{
  width: 100%;
}
@media only screen and (min-width: 1800px) {
  .nft-images {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 10px;
  }
  .user-profile-container {
    width: 90%;
  }
  .user-profile {
    display: flex;
    justify-content: center;
  }
  .profile-nft-images {
    width: 230px !important;
    height: 300px !important;
    max-width: 350px !important;
  }
}

@media only screen and (max-width: 1200px) {


  .payment {
    gap: 20px;
  }

  .section-2-body{
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 1165px) {
  .user-profile {
    overflow: auto;
  }

  .user-profile-container {
    margin: auto !important;
    padding: 0px 65px 0px 20px;
  }

  .transfer-2-options{
    gap: 20px;
  }
  /* .payment{
    flex-direction: column;
    column-gap: 100px;
  } */
}

@media only screen and (max-width: 1000px) {
  .user-profile-container {
    margin: auto !important;
    padding: 0px 95px 0px 20px;
  }

  .profile-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section-1-subdata {
    margin-top: 15px;
  }
  .section-1-grey {
    margin-top: 15px;
  }
  .profile-avatar {
    height: 100px;
    width: 100px;
    margin-top: -60px;
  }
  .profile-section-2 {
    width: unset;
    margin-top: 30px;
  }

  .section-2-body{
    padding: 30px 5px;
  }
  /* .user-edit-form{
    padding-left: 15%;
  } */
}

@media only screen and (max-width: 788.98px){
  .section-2-body{
    padding: 30px !important;
  }

  .button-link{
    max-height: 50px;
    height: 100%;
  }
  .section-2-heading button{
    margin: 0px;
    padding: 0 10px;
    width: 100%;
  }

  .section-2-heading{
    width: 100%;
    max-width: 745px;
  }
}
@media only screen and (width < 769px) {
  .user-profile {
    width: 100vw;
  }

  .profile-nft-images {
    width: 180px !important;
    height: 200px !important;
    max-width: 350px !important;
    border-radius: 20px;
    margin-top: 10px;
  }
  .user-profile-container {
    padding: 0px 0px;
  }
  .profile-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section-1-subdata {
    margin-top: 15px;
  }
  .section-1-grey {
    margin-top: 15px;
  }
  .profile-avatar {
    height: 100px;
    width: 100px;
    margin-top: -60px;
  }
  .profile-section-2 {
    width: unset;
    margin-top: 30px;
  }
  .cashout-profile-form {
    display: grid;
    place-content: center left;
  }
}

@media only screen and (max-width: 669.98px){
  .payment{
    flex-direction: column;
    gap: 50px;
  }

  .section-1-name{
    margin: 0 auto !important;
  }

  .section-2-heading{
    flex-direction: column;
  }

  .section-2-heading button{
    margin: 0 auto;
    padding: 0 10px;
    max-width: 198px;
    width: 100%;
  }
}

@media only screen and (max-width: 590px){
  .payment{
    flex-direction: column;
    margin: auto;
  }
}
@media only screen and (max-width: 587px) {
  .profile-section-2 {
    padding: 0px 10px;
  }
  .won-money {
    width: 90%;
  }

  .won-fund {
    font-size: 14px;
  }

  .section-2-options{
    width: 100%;
    margin: auto;
  }
}

@media only screen and (width < 572px) {
  .profile-nft-images {
    width: 160px !important;
    height: 180px !important;
    max-width: 350px !important;
    border-radius: 20px;
    margin-top: 10px;
  }
}
@media only screen and (width < 500px) {
  .nft-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 10px 10px;
    row-gap: 24px;
  }
  .profile-input {
    outline: none;
    border: none;
    width: 100%;
    padding: 15px 10px;
    border-radius: 10px;
    background-color: #dee2e6;
    margin: 5px 0;
    font-size: 13px;
  }
  .file_input label {
    width: 320px !important;
    height: 40px;
  }
  .section-2-options{
    height: 85px;
  }
  .section-2-body{
    padding: 30px 0px !important;
  }
}
@media only screen and (width < 376px) {
  .nft-images {
    grid-template-columns: repeat(1, 1fr);
  }
  .user-title {
    font-size: 30px;
  }
  .button-link {
    width: 200px !important;
  }
  .section-1-name {
    font-size: 20px;
  }
  .section-1-subdata {
    font-size: 14px;
  }
  .header-nft-images {
    font-size: 20px;
  }
  .section-1-grey {
    font-size: 14px;
  }
  .transfer-2-options{
    gap: 10px;
  }
}
@media only screen and (width < 322px) {
  /* .profile-input {
    width: 290px;
    padding: 8px;
    font-size: 13px;
  } */
  /* .file_input label {
    width: 290px !important;
    height: 35px;
  } */
  .remove-input-padding input{
    padding: 12px 10px !important; 
  }
}
@media(max-width:991.98px){
  .user-title {
    margin-bottom: 35px;
  }
  .section-2-heading{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .section-2-body{
    padding: 30px 70px;
  }
  .section-1-name{
    margin: 0px;
  }
  .payment{
    justify-content: space-between
  }
  .tranfer-options {
    margin-top: 30px;
  }
  .profile-section-1{
    max-width: 356px;
    width:100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:768.98px){
  .app-name {
    font-size: 17px;
    line-height: 25px;
  }
  .app-icon {
    width: 36px;
  }
  .section-2-options{
    gap: 15px;
  }
  .choose-icon {
    width: 30px;
    height: 30px;
  }
  /* .section-2-connect {
    gap: 15px;
  } */
  .profile-input, .select-field {
    max-width: 100%;
  }
  .datePickerContainer > div, .file_input .input-file-wrapper{
    width: 100% !important;
    max-width: 100% !important;
  }
}

.payment-text{
  width: 264px;
  color: #FFF;
  text-align: center;
  font-family: Bungee;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.deposite-txt-color{
  color: #1DCADC;
  text-align: center;
  font-family: Bungee;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
.cashout-text-color{
  color: #0ef06c;
  text-align: center;
  font-family: Bungee;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
.reminder-text-center{
  text-align: center;
}
.Reminder-text {
  color: #b4b4b4;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .115em;
  letter-spacing: .11em;
  line-height: 21px;
}
.paypal-cashout-modal-header {
    color: #1dcdca !important;
    font-family: bungee, Regular !important;
    font-size: 33px !important;
}
.section-1-referral-account-status{
  display: flex;
  gap: 32px;
  margin-bottom: 27px
}
.section-1-referral-code{
    background-color: #494859;
    margin: 0px auto !important;
    padding: 6px;
    width: 110px;
    margin: 0 auto;
    border-radius: 25px;
    color: #1DCADC;
}
.section-1-referral-code:hover{
  cursor: pointer;
}
.copy-btn-icon{
  margin-left: 2px;
  margin-top: -2px;
}

.paypal-buttons {
  min-width: 350px !important;
}
.avtar-text-center{
  justify-content: center;
  align-items: center;
}
.ow-header-info{
  font-weight: 300;
  color: white;
  font-size: 15px;
  font-family: 'Bungee';
}
.ow-text-info{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 5px;
}
.ow-info-section{
  max-width: 552px;
  width: 100%;
}
.ow-login-btn{
  background: linear-gradient(180deg, rgb(29, 202, 220) 0%, rgb(88, 44, 127) 100%);
  border-radius: 10px;
  height: 35px;
  width: 190px;
  margin-top: 10px;
  margin-bottom: 10px;
}