.login_container {
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Audiowide';
	margin: 25px 0;
}

.login_form_container {
	width: 1400px;
	background-color: #f5f5f5;
	height: 80vh;
	display: flex;
	border-radius: 9px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.overwolf_login_background{
	background: #292929;
	border: #000000 3px solid;
	flex-direction: column;
	border-radius: 5px;
	width: 250px;
	height: 380px;
	align-items: center;
	justify-content: center;
	display: flex;
	margin: 5px 50px;
}
.overwolf_login_button{
	display: flex;
	font-size: 14px !important;
	margin-top: 50px;
	align-items: center;
	justify-content: center;
	background: #000000;
	box-shadow: 3px 3px 4px #585858;
	color: white;
	width: 300px;
	height: 60px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}
.overwolf_login_button:hover{
	box-shadow: 1px 1px 3px #585858;
	transform: translate(2px, 2px);
}
.overwolf_login_image{
	display: flex;
	margin-right: 20px;
	width: auto;
	height: 100%;
}
.or_divider{
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Bungee", sans-serif;
	font-size: 18px;
	color: #505050;
	margin-top: 20px;
	margin-bottom: 15px;
}
.left{
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
    color: #1b193c;
    /* font-size: 30px; */
    margin-top: 0;
    font-family: 'Audiowide';
}

.left_inner{
	margin-top: 35px;
	align-items: center;
	justify-content: center;
	display: flex;
	gap: 15px;
	font-size: 14px;
	font-family: "Roboto", sans-serif;
}

.left_inner_register{
	margin-top: 10px;
	align-items: center;
	justify-content: center;
	display: flex;
	gap: 15px;
	font-size: 14px;
	font-family: "Roboto", sans-serif;
}

.register_link{
	font-size: 14px;
	padding: 6px;
	border: none;
    outline: none;
    background: var(--Grrr,linear-gradient(245deg,#1dcadc 0,#582c7f 100%));
    margin-left: 8px;
    color: #FFFFFF;
	transition: all 0.2s ease-in-out;
}

.register_link:focus{
	border: none;
	outline: none;
}

.register_link:hover,
.register_link:active {
	transform: scale(0.98);
}

.login_btn{
	background:var(--Grrr,linear-gradient(245deg,#1dcadc 0,#582c7f 100%));
	color: white;
	border: none;
	outline: none;
	padding: 6px 0;
	margin-top: 15px;
	border-radius: 9px;
	width: 120px;
	font-weight: normal;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}

.login_btn:hover, .login_btn:active{
	transform: scale(0.98);
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.form_container h1 {
	font-size: 40px;
	margin-top: 0;
	font-family: "Bungee", sans-serif;
	color: #1b193c;
}

.input {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #dee2e6;
	box-shadow: inset 2px 2px 5px #747474;
	font-size: 14px;
	color: #1b193c;
}

.error_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.right {
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	/* background-color: #1b193c; */
	background: #0f0c29;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
	background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	/* background-image: url("../images/logo-2.png");
	background-position: center;
	background-size: cover; */
}

.right_inner{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto 0;
}

.right_inner img{
	width: 400px;
}

.right h1 {
	margin-top: 0;
	color: white;
	font-size: 40px;
	align-self: center;
	font-family: "Audiowide", sans-serif;
}

.white_btn,
.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 150px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.green_btn {
	background:#4A6BBC;
	color: white;
	margin: 10px;
}
a{
	text-decoration: none;
	list-style: none;
}

@media only screen and (max-width: 778px) {
		
		.login_form_container {
			width: 100%;
			flex-direction: column-reverse;
			font-size: 30px;
		}
		.right{
			/* flex: 1; */
			display: none;
		}
		.form_container {
			font-size: 35px;
		}
	}



@media only screen and (max-width: 667px) {
  
    .right h1 {
        font-size: 30px;
    }
   .form_container h1 {
        font-size: 30px;
    }
   .form_container {
	align-items: center;
		display: flex;
		flex-direction: column;
		width: 100% !important;
    }
	.login_form_container {
		padding: 0px 10px;
	}
}
@media only screen and (max-width: 558px) {
	.login_container{
		height: 70vh;
	}
	.login_form_container{
		height: 65vh;
	}

	.login_form_container h1 {
		font-size: 30px;
		margin-top: 0;
		line-height: 36px;
	}
	.input {
		width: 300px;
		padding: 11px;
		font-size: 13px;
	}
	.login_btn{
		font-size: 13px !important;
		padding: 10px 0;
		width: 90px;
	}
	.left_inner{
		font-size: 13px;
	}
}

@media (max-width: 425px){
	.login_form_container h1{
		font-size: 25px;
	}
	.input{
		width: 250px;
	}
	.register_link{
		font-size: 13px !important
	}
}