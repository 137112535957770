.public-container{
    max-height: 100%;
    margin-top: 12%;
    overflow-y:auto
}
.tabel-top{
    position: relative;
}

.border-none td{
    border: none;
    vertical-align: middle;
}
.border-none th{
    border: none;
}
.border-none{
    border: none;
}

.border-none{
    background-color: #3C2485 ;
}
.trans-head{
    margin-top: 30px !important;
}
.user-no-trans-msg{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    padding: 10px;
    top: 50%;
    position: relative;
}