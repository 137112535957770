.login_container {
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Audiowide";
  margin-top: 5%;
}
.admin_container {
  min-height: 100vh !important;
}

.login_form_container {
  width: 500px;
  height: 60vh;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b193c;
  border-radius: 10px;
  align-self: center;
  color: #1b193c;
  font-size: 30px;
  margin-top: 0;
  font-family: "Audiowide";
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  justify-content: center;
}

.form_container h1 {
  font-size: 40px;
  margin-top: 0;
}

.input {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  background: #dee2e6 !important;
  margin: 12px 0;
  font-size: 14px;
  height: 44px;
}

.error_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.right {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b193c;
  background-size: cover;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.right h1 {
  margin-top: 0;
  color: white;
  font-size: 40px;
  align-self: center;
}

.white_btn,
.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.green_btn {
  background: #4a6bbc;
  color: white;
  margin: 10px;
}
a {
  text-decoration: none;
  list-style: none;
}

@media only screen and (max-width: 778px) {
  @media only screen and (max-width: 778px) {
    .login_form_container {
      /* width: 100%;
      flex-direction: column-reverse;
      font-size: 35px; */
      width: 75%;
      font-size: medium;
    }
    .right {
      flex: 1;
    }
    .form_container {
      font-size: 20px;
      font-family: "Roboto", sans-serif;
    }
    .admin_logo {
      width: 200px;
    }
    .input {
      width: 70%;
    }
    .green_btn {
      width: 100px;
    }
  }
}

@media only screen and (max-width: 667px) {
  .right h1 {
    font-size: 30px;
  }
  .form_container h1 {
    font-size: 26px;
  }
  .form_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .login_form_container {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 558px) {
  .login_form_container h1 {
    font-size: 25px;
    margin-top: 0;
    line-height: 36px;
  }
}
