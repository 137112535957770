.inner-space-wrap-status {
  padding: 160px 0px 220px 0px;
}

.user-status h2 {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 71px;
  letter-spacing: 0.025em;
  color: #ffffff;
}

.user-status image {
  position: relative;
}

.user-status h2 {
  position: absolute;
}

.fund-status {
  margin: 24px 256px;
}
.available-funds {
  background-color: #212034;
  padding: 8px;
  border-radius: 10px;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.result-money {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 73px;
  line-height: 93px;
  color: #d82626;
}

.win {
  color: #1DCADC;
  font-family: 'Bungee';
}

.lost {
  color: #d82626;
  font-family: 'Bungee';
}

.updated-funds {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.108em;
  color: #1DCADC;
}

.updated-funds-title{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.108em;
  color: #B4B4B4;
}
.challenge-btns {
  justify-content: center;
  /* margin-top: 80px; */
}

.challenge-btns button {
  background-color: #2d3d6e;
  width: 180px;
  padding: 12px;
}

.result {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.125em;
  color: #ffffff;
}

.live-res {
  line-height: 32px;
  letter-spacing: 0.125em;
}

.game {
  font-family: "Audiowide";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25em;
  text-decoration-line: underline;
  color: #b4b4b4;
}

.user {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #0ef06c;
}

.challenge-actions {
  text-transform: none;
  font-size: 20px !important;
}
.deposit {
  color: #0ef06c;
}

.decision-manual{
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.125em;
  color: #ffffff;
  text-align: center;
}
.challenge-no{
  font-family: "Bungee";
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding-top: 10px;
}
@media only screen and (max-width: 992px) {
  .section-title h2 {
    font-size: 50px !important;
    line-height: 64px !important;
  }
  .fund-status {
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .text-end {
    text-align: center !important;
  }

  .text-start {
    text-align: center !important;
  }

  .challenge-actions button {
    text-align: center;
    margin-top: 20px;
  }

  .challenge-btns button {
    height: 100%;
    width: 250px;
  }
  .challenge-btns{
    margin-top: 30px !important;
  }
  .inner-space-wrap-status{
    padding: 160px 0px 0px 0px;
  }
}

@media only screen and (max-width: 462px) {
  .section-title h2 {
    font-size: 25px !important;
    line-height: 35px !important;
  }
}