.purchase-main{
    min-height: 100vh;
    background-image: url(../images/home-img-shape.png);
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center;
    padding-top: 65px !important;
}


.purchase-body{
    /* margin-top: 20px; */
    display: flex;
    flex-direction: row;
    min-height: 75vh;
}
.purchase-body-1{
    width: 60%;
    
    /* border: 1px solid white; */
}
.purchase-body-2{
    width: 40%;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
}
.purchase-body-1-1{
    height: 100%;
}
.purchase-body-1-2{
    /* height: 25%; */
}
.purchase-img{
    height: 100px;
    width: 100px;
}

.purchase-image{
    border-radius: 10px;
    border:1px solid grey;
    height: 394px;
    width: 700px;
    margin-top: 4%;
}
.purchase-video{
    width: 700px;
    height: 450px;
}
.purchase-body-1-1{
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.purchase-1-userdata{
    padding: 10px;
    border: 0.5px solid white;
    width: 80%;
    margin-top: 8%;
    margin-left: 8%;
    border-radius:10px;
    background-color: #06001b;
}
.purchase-1-name{
    font-size: 25px;
    color: white;
    font-family: 'Audiowide';
}
.purchase-1-subdata{
    color: rgb(171, 171, 171);
    margin-top: 8px
}
.purchase-1-bg{
    display: flex;
    justify-content: space-between;
    color: white;
    background: #4a6bbc;
    padding: 5px 15px;
    margin-top: 20px;
    border-radius: 10px;
}
.purchase-2-userdata{
    margin-top: 15px;
    margin-left: 8%;
    padding: 8px 10px;
    /* border: 0.5px solid white; */
    width: 80%;
    display: flex;
    justify-content: space-between;
    border-radius:10px;
    background-color: #06001b;
}
.price{
    display: flex;
    flex-direction: column;
  }
  .price-value{
    color:white;
    width:180px;
    /* border:1px solid grey; */
    /* background-color: grey; */
    padding:5px 0px;
    margin-left:10px;

    border-radius:10px;
  }
  .price-label{
    color:grey;
    margin-left:10px;
    font-size: 14px;
  }
  .purchase-btn{
    padding: 0px;
    height: 40px;
  }
@media (width > 1500px) {
    .purchase-body{
        min-height: 50vh;
    }
    .purchase-video{
        width: 700px;
        height: 550px;
    }
    /* .purchase-1-userdata{
        margin-top: 13%;
    } */
    .purchase-video{
        width: 750px;
        height: 500px;
    }

}
@media only screen and (width < 1025px){
    .purchase-video{
        width: 600px;
        height: 450px;
    }
    .purchase-image{
        height: 450px;
        width: 600px;
    }
}
@media only screen and (width < 769px){
    .purchase-body{
        /* margin-top: 20px; */
        display: flex;
        flex-direction: column;
        min-height: 900px;
    }
    .purchase-body-1{
        width: unset;
        
        /* border: 1px solid white; */
    }
    .purchase-body-2{
        width: unset;
        /* border: 1px solid white; */
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (width < 426px){
    .purchase-video{
        width: 400px;
        height:350px;
    }
    .purchase-image{
        height: 350px;
        width: 400px;
    }
    .purchase-body-2{
        padding: 0px;
    }
    .purchase-2-userdata{
        width:100%;
        margin-left:0px
    }
    .purchase-1-userdata{
        width:100%;
        margin-left:0px

    }
    .purchase-1-subdata{
        font-size: 15px;
    }
    .purchase-1-name{
        font-size: 20px;
    }
    .price-value{
        font-size: 14px;
    }
}
@media only screen and (width < 400px){
    .purchase-video{
        width: 350px;
        height:300px;
    }
    .purchase-image{
        height: 300px;
        width: 350px;
    }
}
@media only screen and (width < 350px){
    .purchase-video{
        width: 300px;
        height:250px;
    }
    .purchase-image{
        height: 250px;
        width: 300px;
    }
}
