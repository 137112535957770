.challenge_content {
    display: flex;
    width: 100%;
  }
  .challengetable {
    width: 80vw;
    min-height: 100vh;
  }
  .scrolltable {
    overflow-x: auto;
    margin: 15px;
    -webkit-overflow-scrolling: touch;
  }
  .aghhaa {
    --bs-table-bg: rgb(6, 0, 27);
    --bs-table-striped-bg: #0b0b27;
    border-collapse: separate;
  }
  .loader-hight {
    height: 73vh;
  }

  .MuiFormLabel-root{
    color: white !important;
  }
  .MuiMenu-list{
    color:white !important;
    background: #282454 !important;
  }
  .MuiMenuItem-root:hover{
    background: #0b0b27 !important;
  }
  .MuiFormControl-root{
    margin-right: 10px;
  }
  .MuiSelect-root{
    color: white !important;
    background-color:#282454 !important;
  }
 .MuiSelect-select.MuiSelect-select{
    border-radius: 40px;
    height: 23px;
    padding-left: 14px;
    padding-top: 12px;
    padding-bottom:9px
  }
  .MuiDrawer-paper{
    background: #111126;
    width: 560px;
  }
  .drawer-header{
    color: white;
    display: flex;
    justify-content: center;
    padding: 7px 0;
    background-color:#1b1b34 ;
  }
  .player-1{
    padding: 0px 15px;
  }
  .player-1-info{
    color: white;
    margin-top: 15px !important;
    font-size: 17px;
  }
  .grid-1{
    margin-top: 15px !important;
    color:white !important
  }

  .player-2{
    padding: 0px 15px;
  }
  .player-2-info{
    color: white;
    margin-top: 15px !important;
    font-size: 17px;
  }
  .grid-2{
    margin-top: 15px !important;
    color:white !important
  }
  .Selection-Header{
    background-color: #1b1b34;
    color:white;
    text-align: center;
  }
  .Selection-Body{
    background-color: #06001b;
    color:white;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .force-win{
    background-color: rgb(0, 100, 0);
    border-color: rgb(0, 100, 0);
    &:hover{
      background-color: rgb(0, 0, 0);
    }
  }
  .force-lose{
    color: white;
    background-color: rgb(180 , 0, 0);
    border-color: rgb(180, 0, 0);
    &:hover{
      background-color: rgb(0, 0, 0);
    }
  }
  .force-delete{
    background-color: rgb(0 , 0, 0);
    border-color: rgb(200, 0, 0);
    font-size: 12px !important;
    width: 70%;
    padding: 5px;
    align-self: right;
    &:hover{
      background-color: rgb(255, 0, 0);
    }
  }
  .MuiGrid-spacing-xs-2{
    width: 100%;
    margin: 0px;
  }
  .inner-grid-1{
    display: flex;
    align-items: center;
    background-color: #06001b;
    padding-left:5px ;
  }
  .inner-grid-2{
    background-color:#1b1b34;
    display: flex;
    align-items: center;
    padding-left:10px ;
  }
  .radio-form{
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    align-items: center !important;
  }
  .radio-options{
    width: 60%;
    display: flex;
    justify-content: space-around;
  }

  .fixed-position{
    position: absolute;
    right: 6px;
    top:13px
  }
  .search-bar-container-admin .MuiInputLabel-formControl {
    position: relative !important;
    margin-left: 10px;
  }
  .search-bar-container-admin {
    margin-bottom: 10px;
  }
  .search-bar-container-admin .search-bar .input-group.md-form.form-sm.form-2 input.red-border {
    border-radius: 25px !important;
    padding-left: 10px;
  }
  
  @media (max-width: 560px){
    .MuiDrawer-paper{
      width: 400px;
    }
  }

  @media (max-width: 400px) {
    .MuiDrawer-paper{
      width: 300px;
    }
  }

  @media (max-width: 670px){
    .search-bar-container-admin{
      flex-direction: column;
    }
  }