.thank-sec {
    background-color: #0B1124;
    padding: 40px 0px;
    height: 80vh;
    display: flex;
    align-items: center;
  }
  .thnkct {
    text-align: center;
    background: #282454;
    max-width: 650px;
    margin: auto;
  
    padding: 30px;
    border-radius: 20px;
    font-family: "Roboto", sans-serif;
  }
  .thnkct h2 {
    font-family: "Roboto", Regular;
    font-size: 2em;
    color: white;
    margin: 0;
    margin-bottom: 20px;
  }
  .thnkct h4 {
    font-size: 1.3rem;
    font-family: "Roboto", Regular;
    color: #fff;
    /* font-family: Regular; */
  }
  .thnkct p {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .thankbtn {
    padding-top: 20px;
  }
  .thnkct {
    text-align: center;
  }
  
  /* Decision Info Page Styles */
  
  .decision-info-section {
    background-color: #121027;
    padding: 30px 0px;
    height: 80vh;
    display: flex;
    align-items: center;
  }
  
  .decision-info-text {
    text-align: center;
    background: #282454;
    max-width: 650px;
    margin: auto;
    padding: 30px;
    border-radius: 20px;
    color: white;
  }
  
  .decision-info-text h1 {
    font-family: "Bungee", sans-serif;
    margin-bottom: 20px;
  }
  
  .decision-info-text h2 {
    font-family: "Roboto", sans-serif;
    font-size: 25px;
  }
  
  /* responsive */
  @media only screen and (max-width: 667px) {
    .thnkct h1 {
      font-size: 35px;
    }
    .thank-sec {
      height: 350px;
    }
  
    .decision-info-section {
      height: 59.2vh;
    }
  
    .decision-info-text h1 {
      font-size: 25px;
    }
  
    .decision-info-text h2 {
      font-size: 20px;
    }
  }
  