.loading {
  width: 100vw;
  min-height: 100px;
  /* height: 100%; */
  background-color: transparent;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 3vmax;
  height: 3vmax;
  border: 8px solid #a256f9;
  border-radius: 20%;
  animation: loadingRotate 1500ms linear infinite;
}

@keyframes loadingRotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(-1turn);
  }
}

@media only screen and (max-width: 772px) {
  .loading > div {
    width: 8vmax;
    height: 8vmax;
    border: 5px solid #a256f9;
  }
}


