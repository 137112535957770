.features-container{
    margin-top: 70px;
    margin-bottom: 70px;
}
.features-title h3{
    font-family: 'Bungee';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF;
    margin-bottom: 28px;
    margin-top: 45px;
}
.features-title .feature-blue-text{
    font-family: 'Bungee';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #1DCADC;
    margin-bottom: 28px;
}
.features-profit-title h3{
    font-family: 'Bungee';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF;
    margin-bottom: 28px;
    text-align: center;
}
.features-profit-title .feature-blue-text{
    font-family: 'Bungee';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #1DCADC;
    margin-bottom: 28px;
}
.image-2{
    box-shadow: 0px 0px 44px 10px rgba(4, 227, 26, 0.45);
    border-radius: 30px;
    max-width: 524px;
    width: 100%;
    height: auto;
    margin-top: 45px;
}

.features-content{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    /* max-width: 496px;
    margin-bottom: 27px; */
}

.contact-btn{
    font-size: 24px !important;
    background-color: #4b6ebc;
    padding: 6px 24px;
    border: 1px solid #4b6ebc;
    color: #fff;
    text-transform: capitalize;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
    margin-top: 28px;
    max-width:170px;
    width: 100%;
}

.features-img {
    width: 50%;
    height: 425px;
}

@media only screen and (max-width: 991.98px){
    .features-title{
        text-align: center;
        order: 1;
        margin-bottom: 40px;
    }
    .features-profit-title{
        text-align: center;
        order: 1;
        margin-bottom: 40px;
    }
    .features-img img{
        order: 2;
    }  
}

@media only screen and (max-width: 568px){
    .features-content{
        /* margin-top: 350px; */
    }
    .top-features,
    .bottom-features {
    display: block;
  }

    .top-features,
    .bottom-features,
    .features-left,
    .features-right {
        gap: initial;
    }

  .features-right img {
    display: block;
    margin-right: 70px;
    position: absolute;
    /* margin-top: -480px; */
    width: 100%;
    max-width: 400px;
  }
  .features-left img{
    display: block;
    margin-right: 70px;
    
    margin-top: 220px;
    width: 400px;
  }
  .bottom-features h3{
  margin-top: -350px;
  }
}
/* add new css on 15-06-23 */
.image-1{
    box-shadow: 0px 0px 44px 10px #0B3CC9;
    border-radius: 30px;
    max-width: 524px;
    width: 100%;
    height: auto;
}
.features-space{
    padding-top: 80px;
    /* padding-bottom: 80px; */
}
.features-space .row:not(:last-child){
    margin-bottom: 80px;
}
@media(max-width:991.98px){
    .features-title h3{
        order: 1;
    }
    .features-profit-title h3{
        order: 1;
    }
    .features-img{
        order: 2;
        text-align: center;
        width: 100%;
    }
    .features-img img{
        max-width: 90%;
    }
    .features-content{
        order: 3;
    }
    .contact-btn{
        order: 4;
    }
    .features-space .row:not(:last-child){
        margin-bottom: 50px;
    }
    .features-content{
        max-width: 100%;
    }    
    .features-space {
        padding-top: 50px;
        /* padding-bottom: 50px; */
    }
    .features-title h3 {
        margin-bottom: 20px;
    }
    .features-profit-title h3 {
        margin-bottom: 20px;
    }
}

lite-youtube {
    padding-bottom: 425px !important;
    border-radius: 16px;
}