.user_content {
  display: flex;
  width: 100%;
}
.user_slide {
  width: 20vw;
}
.user_head {
  padding-top: 20px;
  text-align: center;
  margin-bottom: 22px;
}
.user-avatar { 
  width: 2.8rem;
  height: 2.8rem;
}
.table_A {
  width: 80vw;
  min-height: 100vh;
}
.ad {
  color: blue;
}
.td-left {
  text-align: left;
  padding-left: 1.1rem !important;
}
.aghh {
  --bs-table-bg: rgb(6, 0, 27);
  --bs-table-striped-bg: #0b0b27;
  border-collapse: separate;
}
.modal-title {
  font-family: Audiowide, Regular;
  font-size: 22px;
  color: white;
}
.modal-header {
  background-color: #06001b;
  padding: 10px 10px;
}
.modal-footer {
  background-color: #06001b;
  padding: 5px 5px;
}
.modal-body {
  background-color: #0b0b27;
  color: white;
}
.modal-content {
  border: 1px solid rgb(154, 152, 152);
}
.btn-primary {
  background: var(--Grrr, linear-gradient(245deg, #1DCADC 0%, #582C7F 100%));
  border-color: #4a6bbc;
}
.btn-primary:hover {
  background-color: #4a6bbc;
  border-color: #4a6bbc;
}
.form-input {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  background-color: #edf5f3;
  margin: 5px 0;
  font-size: 14px;
}
.none-bg {
  background: none;
  border: none;
}
.none-bg:hover {
  /* transform: scale(1.3); */
}
.none-bg:hover,
.none-bg:active,
.none-bg:focus {
  background: none;
  border: none;
  box-shadow: none !important;
}
.media_scroll {
  margin-bottom: 20px;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: white !important;
}
.MuiTableSortLabel-root:hover {
  color: white !important;
}
.MuiTableSortLabel-root:active {
  color: white !important;
}
.MuiIconButton-root.Mui-disabled {
  color: grey;
}
.MuiSelect-icon {
  color: white;
}
.MuiSvgIcon-root {
  font-size: 18px;
}
.MuiTablePagination-caption {
  font-family: "Roboto", sans-serif;
}
.MuiTableCell-root {
  padding: 12px;
  border-bottom: none;
}
.checkbox {
  margin-left: 5px !important;
}
.form-check-input:checked {
  background-color: #4a6bbc;
  border-color: #4a6bbc;
}
.test::placeholder {
  color: black !important;
}
.text-control {
  background-color: rgb(135, 134, 134);
  color: black !important;
}

.search-form {
  justify-content: flex-end;
  width: 100%;
  margin-left: 17rem;
}
.search-bar-container-admin {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 650px) {
  .media_scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media only screen and (max-width: 992px) {
  .user_slide {
    width: 0vw;
  }
}
  .user_head {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 22px;
  }
  .table_A {
    width: 80vw;
    min-height: 100vh;
  }
  .ad {
    color: blue;
  }
  .td-left {
    text-align: left;
    padding-left: 1.1rem !important;
  }
  .aghh {
    --bs-table-bg: rgb(6, 0, 27);
    --bs-table-striped-bg: #0b0b27;
    border-collapse: separate;
  }
  .modal-title {
    font-family: Roboto, Regular;
    font-size: 22px;
    color: white;
  }
  .modal-header {
    background-color: #06001b;
    padding: 10px 10px;
  }
  .modal-footer {
    background-color: #06001b;
    padding: 5px 5px;
  }
  .modal-body {
    background-color: #0b0b27;
    color: white;
  }
  .modal-content {
    border: 1px solid rgb(154, 152, 152);
  }
  .btn-primary {
    background-color: #4a6bbc;
    border-color: #4a6bbc;
  }
  .btn-primary:hover {
    background-color: #4a6bbc;
    border-color: #4a6bbc;
  }
  .form-input {
    outline: none;
    border: none;
    width: 370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 5px 0;
    font-size: 14px;
  }
  .none-bg {
    background: none;
    border: none;
  }
  .none-bg:hover {
    /* transform: scale(1.3); */
  }
  .none-bg:hover,
  .none-bg:active,
  .none-bg:focus {
    background: none;
    border: none;
    box-shadow: none !important;
  }
  .media_scroll{
    margin-bottom: 20px;
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active{
    color: white !important;
  }
  .MuiTableSortLabel-root:hover{
    color: white !important;
  }
  .MuiTableSortLabel-root:active{
    color: white !important;
  }
  .MuiIconButton-root.Mui-disabled{
    color: grey;
  }
  .MuiSelect-icon{
    color: white;
  }
  .MuiSvgIcon-root{
    font-size: 18px;
  }
  .MuiTablePagination-caption{
    font-family: 'Roboto',sans-serif;
  }
  .MuiTableCell-root{
    padding: 12px;
    border-bottom: none;
  }
  .checkbox{
    margin-left: 5px !important;
  }
  .form-check-input:checked {
    background-color: #4a6bbc;
    border-color: #4a6bbc
  }
  .test::placeholder{
    color: black !important;
  }
  .text-control{
    background-color: rgb(135, 134, 134);
    color: black !important;
  }

  .search-form{
    justify-content: flex-end;
    width: 100%;
    margin-left: 17rem;
    
  }
  .search-bar-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  @media (max-width: 650px) {
    .media_scroll {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  
