.monitor-card-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;
    gap: 20px;
}
.monitor-card-wrapper{
    background-color:#23252e;
    box-shadow: 0px 2px 10px rgba(3, 3, 3, 0.09);
    border-radius: 15px;
    padding: 8px;
    min-width: 15.125rem;
    height: 9.8125rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.monitor-header-text{
    color: #FFF;
    font-family: Bungee;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    max-width: 15.125rem;
}
.monitor-desc-text{
    color: #FFF;
    font-family: Bungee;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 400;
}