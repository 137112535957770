.rules-space{
    padding: 80px 0;
}

.card-wrapper1 img{
    position: relative;
 
}

.leaderboard{
    margin-top: 80px;
}


.card-wrapper1 p{
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    /* font-size: 16px; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding-top: 22px;
    max-width: 271px;
    width: 100%;
}

.card-wrapper1{
    background: var(--Grrr, linear-gradient(245deg, #1DCADC 0%, #582C7F 100%));    
    box-shadow: 10px 5px 5px rgba(3, 3, 3, 0.897);
    border-radius: 6px;
    min-height: 275px;   
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gx-45{
    --bs-gutter-x: 45px;
}

@media(max-width:991.98px){
    .gx-45 {
        --bs-gutter-x: 20px;
    }
    .rules-space{
        padding: 50px 0;
    }
}
@media(max-width:768.98px){
    .gx-45 {
        --bs-gutter-x: 20px;
        --bs-gutter-y: 20px;
    }
    .card-wrapper1{
        min-height: 270px;   
    }
}
@media only screen and (max-width: 568px){
   .gx-40{
    --bs-gutter-y:40px
   }
   .card-wrapper1 img{
   /* margin-left: 160px; */
   }
   
}

@media only screen and (max-width: 778px){
    .gx-40{
        --bs-gutter-y:40px;
       
       }
       /* .card-wrapper1{
        max-width: 80%;
       } */
}