.reset_container {
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Audiowide';
	margin: 25px 0;
}

.reset_form_container {
	width: 1400px;
	background-color: #f5f5f5;
	height: 80vh;
	display: flex;
	border-radius: 9px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.reset-left{
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.reset_left_inner{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto 0;
}

.reset_left_inner img{
	width: 400px;
}

.reset_right{
	display: flex;
	flex-direction: column;
    flex: 2;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
    color: #1b193c;
    /* font-size: 30px; */
    margin-top: 0;
    font-family: 'Audiowide';
}

.reset-label{
    font-family: "Audiowide";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1b193c;
    margin-top: 15px;
    width: 370px;
}

.reset_btn{
	background:#4A6BBC;
	color: white;
	border: none;
	outline: none;
	padding: 10px 0;
	margin-top: 15px;
	border-radius: 20px;
	width: 180px;
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.reset_login{
	margin-top: 10px;
	align-items: center;
	justify-content: center;
	display: flex;
	gap: 15px;
	font-size: 14px;
	font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 778px) {
		
	.reset_form_container {
		width: 100%;
		flex-direction: column-reverse;
		font-size: 30px;
	}
	.reset-left{
		display: none;
	}
	.form_container {
		font-size: 35px;
	}

	.reset_right i{
		font-size: 80px;
	}
}



@media only screen and (max-width: 667px) {

	.form_container {
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100% !important;
	}
	.reset_form_container {
		padding: 0px 10px;
	}
	.reset_right i{
		font-size: 70px;
	}
}
@media only screen and (max-width: 558px) {
	.reset_container{
		height: 70vh;
	}
	.reset_form_container{
		height: 65vh;
	}

	.reset_form_container h2 {
		margin-top: 0;
		line-height: 36px;
	}
	.input {
		width: 300px;
		padding: 11px;
		font-size: 13px;
	}
	.reset_btn{
		font-size: 13px !important;
		padding: 10px 5px;
		width: auto;
	}
	.reset_login{
		font-size: 13px;
	}
	.reset_right i{
		font-size: 75px;
	}
    .reset-label{
        width: 300px;
    }
}

@media (max-width: 425px){
	.reset_form_container h1{
		font-size: 25px;
	}
	.input{
		width: 250px;
	}
	.login_link{
		font-size: 13px !important
	}
	.reset_right i{
		font-size: 50px;
	}
    .reset-label{
        width: 250px;
    }
}