* {
  font-family: "Audiowide", cursive;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
}
/* .container {
  max-width: 1240px;
  margin: auto;
} */

.pad-bot{
	padding-bottom: 20px !important;
}
.btn:hover {
  color: #fff;
}
.body-main {
  padding-top: 65px !important;
}

.hero-btn:hover {
  background: #2a4070;
  border-color: #2a4070;
  transition: 0.6s;
}

.btncl{
  margin-top: 10px;
}

.btncl:hover {
  background: #2a4070;
  border-color: #2a4070;
  transition: 0.6s;
}
button {
  font-size: 14px !important;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.f-20 {
  font-size: 20px !important;
}

@media(max-width:991.98px){
  .container{
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* add new globel css on 22-06-23 */
.btn{
  padding: 7.7px 32px;
  font-size: 23px;
  line-height: 29px;
  font-family: "Bungee";
  background-color: transparent;
  border-radius: 10px;
}
.btn-primary{
  background-color: #4B6EBC;
  color: #fff;
}
.btn-secondary{
  color: #fff;
  border:1px solid #fff
}
.navbar{
  padding-top: 0;
  padding-bottom: 0;
}
@media(max-width:480.98px){
  .btn{
    width: 100%;
  }
}