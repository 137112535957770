.faq {
    background-color: #0B1124;
    padding: 40px 0px;
    min-height:100vh;
}
.contact-link{
    font-size: 13px;
}
.faq-main{
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
}
.terms-service .right-faq-head {
    margin-top: 14px;
}

.data-policy .right-faq-head {
    margin-top: 14px;
}

.terms-service .right-faq-body {
    margin-top: 0px;
}

.data-policy .right-faq-body {
    margin-top: 0px;
}

.faq-container {
    max-width: 1000px;
    margin: auto;
    
}
.right-faq{
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.right-faq-head{
    font-size:30px ;
    font-weight: 500;
    font-family: 'Bungee';
    color: white;
}
.right-faq-body{
    /* padding-right: 150px; */
    margin-top: 15px;
    font-size:16px ;
    color: white;
}
/* .image-container{
    padding: 10px;
} */
.right-faq-main{
    width: 500px;
}
.right-faq-body-2{
    margin-top: 15px;
    color: grey;
    font-size: 15px;
}
.accordion-item{
    background: #141434;
    color: white;
}
.accordion-button{
    background-color:#06001b ;
    color: white;
    font-family: 'Bungee', sans-serif;
    font-weight: 450;
    font-size: 14px !important;
}
.accordion-button:not(.collapsed){
    background-color:#06001b ;
    color: white !important;
    border: none;
}
.accordion-button:focus{
    box-shadow: none;
}
.faq-svg{
    width: 350px;
}
.accordion-body{
    font-size: 14px;
    letter-spacing: 0.5px;
}
.accordion-body > p {
    font-size: 14px;
    letter-spacing: 0.5px;
}
.right-faq-body-3{
    margin-top: 15px;
}
.right-faq-body-3 > .search-bar{
    width: 250px !important;
}
.right-faq-body-3 > .search-bar > .input-group {
    padding: 1.5px !important;
}

.right-faq-body-3 > .search-bar > .input-group.md-form.form-sm.form-2 > input.red-border {
    padding-left: 20px;
    font-size: 16px !important;
}

.right-faq-body-3 > #basic-text1{
    padding-right: 10px !important;
}

.left-faq{
    margin-top: 20px;
    padding: 0 45px;
}
.faq li{
    list-style: disc;
}
.faq ol li{
    list-style: decimal;
}
.countries{
    margin-top: 10px;
    margin-left: 20px;
}

.referral{
    margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
    .left-faq{
        margin-top: 20px;
        padding: 0px;
    }
   .faq-container{
    padding: 40px;
   }
  }
  @media only screen and (max-width: 768px) {
    .faq-svg{
        width: 300px;
    }
    .right-faq-head{
        font-size: 25px ;
    }
    .right-faq-body{
        font-size: 14px;
    }
    .accordion-button{
        font-weight: 430;
        font-size: 13px !important;
    }
    .accordion-body{
        font-size: 14px;
        letter-spacing: 0.3px;
    }
    .accordion-body > p {
        font-size: 13px;
        letter-spacing: 0.5px;
    }
    .faq{
        padding: 0px;
    }
    .right-faq{
        padding: 0px;
    }
   }
   @media only screen and (width < 600px) {
    .faq-svg{
        display: none;
    }
}

@media only screen and (max-width: 598.98px){
    .right-faq-body-3 > .search-bar{
        width: 100% !important;
    }
}

   @media only screen and (width < 426px) {
    /* .right-faq-body-3{
        padding-left: 40px;
    } */
    
    .right-faq-head{
        font-size: 25px ;
        text-align: center;
    }
    .right-faq-body{
        font-size: 14px;
    }
    .faq-container{
        padding:20px;
       }
    .faq-svg{
        display: none;
    }
    .right-faq-body-3 > .search-bar > .input-group {
        padding: 1.5px !important;
    }
    .faq{
        padding: 0px;
    }
    .right-faq{
        padding: 0px;
    }
    .accordion-button{
        font-weight: 400;
        font-size: 11.5px !important;
        line-height: 20px;
    }
    .accordion-body{
        font-size: 13px;
        letter-spacing: 0.5px;
    }
   }
   @media only screen and (width < 377px) {
    /* .right-faq-body-3{
        padding-left: 30px;
    } */
    .contact-link{
        font-size: 12px;
    }
    .faq-container{
        padding:15px;
       }
    .right-faq-head{
        font-size: 20px ;
    }
    .right-faq-body{
        font-size: 13.5px;
    }
   }
   @media only screen and (width < 340px) {
    /* .right-faq-body-3{
        padding-left: 0px;
    } */
}

