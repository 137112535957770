.footer-logo{
    margin-top: 20px;
    margin-bottom: 20px;
}
.footer-social-media-links{
    margin-bottom: 25px;
    margin-top: 30px;
}

.navbar .footer-social-media-links {
    margin-bottom: 10px;
    margin-top: 0;
    gap: 16px;
}
.pitchdeck {
    color: #fff;
    padding: 5px;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Bungee';
}
.pitchdeck:hover {
    color: #6c8cdd !important;
    transition: 0.6s;
}