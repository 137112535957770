.inner-space-wrap {
  padding: 128px 0 120px 0;
}

.duel-left {
  background-color: #343344;
  border-radius: 22px;
  padding: 10%;
}

.duel-receive-body-main {
  padding-top: 0px !important;
}

.cur_user-info {
  border-radius: 10px;
  height: 48px;
  /* padding: 2px 24px; */
  display: flex;
  align-items: center;
  gap: 16px;
}
.cur_tuser-avatar {
  width: 2.8rem;
  height: 2.8rem;
}
.cur_user-name {
  font-weight: 400;
  color: #ffffff;
}

.cur_user-status {
  display: flex;
  gap: 32px;
  margin-top: 10px;
  margin-left: 16px;
  flex: 1;
}

.challenge-status {
  font-family: "Audiowide";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.025em;
}

.challenge-status h4 {
  font-family: "Audiowide";
}
.user-details {
  background-color: #424150;
  box-shadow: 0px 2px 10px rgba(3, 3, 3, 0.09);
  border-radius: 17px;
  padding: 15px;
  margin: 20px;
}

.wager {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b4b4b4;
}

.money {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  color: #1DCADC;
  text-align: center;
  margin-top: 10px;
  /* overflow-y: scroll; */
}
.money::-webkit-scrollbar {
  display: none;
}

.cur-fund {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.115em;
  color: #b4b4b4;
}

button {
  background: rgba(75, 110, 188, 0.65);
  border-radius: 5px;
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  padding: 8px 10px;
}
.challenge-actions button {
  width: "80%";
}
.title {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06em;
  color: #b4b4b4;
}

.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.epic{
  color: #0ef06c;
}
.challenge-content {
  text-align: center;
}

.tickblock {
  margin-left: auto;
  background: #4177ea;
  border-radius: 8px;
}

.cam-scan {
  display: flex;
  justify-content: space-between;
}

.duel-center {
  align-items: center;
  justify-content: center;
}

.duel-right {
  background-color: #242336;
  padding: 24px;
  box-shadow: 0px 2px 10px rgba(3, 3, 3, 0.09);
  border-radius: 17px;
}

.user-history {
  display: flex;
  gap: 24px;
}

.win-status {
  background-color: #343344;
  border-radius: 16px;
}

.winner-details {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.money-won {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 73px;
  color: #1DCADC;
  /* overflow-y: scroll; */
}

.money-won::-webkit-scrollbar {
  display: none;
}
/* .opp_challenge-status {
  display: flex;
  gap: 30px;
} */

.score-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}

.opp_challenge-status {
  font-family: "Audiowide";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  /* letter-spacing: 0.025em; */
}

.opp_challenge-status h6 {
  font-family: "Audiowide";
}
.details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: wrap;
  -ms-flex: wrap 1;
  flex: wrap 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.platform-url {
  background: #282054;
  border-radius: 5px;
  width: 85%;
  color: white;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  height: 36px;
  font-size: 14px;
}

.platform-url {
  padding: 10px;
}
.required-field {
  color: red;
  margin-left: 5px;
}
.details-left,
.details-right {
  flex: 1;
}

.details-right {
  display: flex;
  flex-direction: column;
  align-items: center; /* horizontally center content */
  justify-content: center; /* vertically center content */
}

.main-container {
  padding-top: 40px !important;
}

.no-duel-received {
  background-color: #121027;
  padding: 40px 0px;
  height: 41.2vh;
  display: flex;
  align-items: center;
}

.no-duel-text {
  text-align: center;
  background: #282454;
  max-width: 737px;
  margin: auto;
  padding: 30px;
  border-radius: 20px;
}

.no-duel-text h1 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  color: white;
  margin: 0;
  margin-bottom: 20px;
}

.no-duel-text p {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
}

.player-avatar {
  height: 46px;
  width: 46px;
  border-radius: 100%;
}

.right-player-avatar {
  /* height: 36px;
  width: 36px; */
  border-radius: 100%;
}

.mobile-view{
  display: none;
}

.chal-acc-btn{
  width: 100%;
  max-width: 192.81px;
}
.chal-cont-btn{
  width: 100%;
  max-width: 192.81px;
}
.challenge-number-left{
  text-align: left;
}
.modal-header-font{
  font-family: 'Bungee' !important;
}
.duel-received-win{
  color: #0ef06c !important;
}
@media(max-width: 1013px){
  .money{
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .details {
    gap: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 935px) {
  .duel-right {
    padding: auto;
  }
}

@media(max-width: 907px){
  .money{
    font-size: 32px;
  }
}

@media(max-width: 786px){
  .money{
    font-size: 28px;
  }
}

@media (max-width: 768px){
  
  .web-view{
    display: none;
  }
  .mobile-view{
    display: contents;
  }
  
}

@media (max-width: 767px) {
  .duel-left {
    padding: 5%;
    margin: 45px;
  }

  .web-view{
    display: none;
  }
  .mobile-view{
    display: contents;
  }
  
  .money{
    font-size: 48px;
  }
}

@media (max-width: 560px){
.inner-space-wrap {
    padding: 100px 0 50px 0;
}
}

@media (max-width: 462px){
  .duel-left{
    margin: auto;
  }
  .go-home{
    width: 100%;
    padding: 5px 0px;
    font-size: 18px !important;
  }
}
@media (max-width: 407px) {
  .duel-right {
    padding: 0px !important;
  }
}

@media (max-width:390.98px){
  .no-duel-text{
    
    width: 100%;
    
  }
  .main-container{
   padding-bottom: 40px !important;
  }
}