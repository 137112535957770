.public-container{
    max-height: 100%;
    margin-top: 12%;
    overflow-y:auto
}
.public-chall-padding{
    padding-top: 56px;
}
.tabel-top{
    position: relative;
}

.border-none td{
    border: none;
    vertical-align: middle;
}
.border-none th{
    border: none;
}
.border-none{
    border: none;
}

.border-none{
    background-color: #3C2485 ;
}

.avatar-cell{
        text-align: center !important;
        margin: 0 auto 5px !important;
}
.online-icon{
    width: 20px;
    height: 20px;
}
@media(max-width:669.98px) {
    .public-chall-padding {
        padding-top: 70px;
    }
}

.set-table-height {
    height: calc(100vh - 200px);
    overflow: auto;
    background-color: #121127;
}
.table-height-auto{
    height: auto;
    overflow: auto;
    background-color: #121127;
}
#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: transparent;
    display: none;
}

#style-2::-webkit-scrollbar
{
	width: 6px;
    height: 6px;
	background-color: transparent;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: lightgrey;
}
.public-modal-content{
    max-width: 500px;
    width: 100%;
    max-height:250px;
    height: 100%;
    text-align: center;
}
.public-chal-accept-btn{
    width: 100%;
    max-width: 150px;
    margin:10px !important;
}
.public-chall{
    display: flex;
    justify-content: center;
}
@media(max-width:768.98px){
    .public-modal-content{
        max-height:350px;
    }
}
.home-public-container{
    max-height: 100%;
    margin-top: 0px !important;
    overflow-y: auto;
}