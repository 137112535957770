.challenge-heading {
  text-align: center;
  color: "white";
  margin-top: 10px;
}

.challenge-heading h1 {
  font-family: Bungee, Regular;
  color: white;
}

.sideNavbar {
  position: fixed;
  height: 100vh;
  width: 20vw;
  background-color: rgb(6, 0, 27);
}
.navcontent {
  padding-top: 30px;
}
.adt {
  display: block;
  width: 100%;
  color: white;
  font-family: 'Bungee';
}
.adt:hover {
  color: white;
}
.logoutadmin {
  position: fixed;
  bottom: 20px;
  padding: 0px 15px;
  width: 20%;
}
.iconA {
  color: white;
}
.line-item {
  padding: 10px 15px;
}
.line-item:hover {
  background: #0b0b27;
}
.active-bg {
  background: #0b0b27;
}
.logoimg {
  /* padding-left: 2.5rem; */
}
.side-list {
  padding: 0rem !important;
}
/* .................media start..................... */
@media (max-width: 950px) {
  .logoimg img {
    width: 13vw;
  }
}
@media (max-width: 700px) {
  .iconA {
    display: none;
  }
  .adt,
  .adt23 {
    margin-left: 0px;
  }
  .adt2 {
    margin-left: 0px;
  }
  .logoimg img {
    width: 13vw;
  }
  @media (max-width: 580px) {
    .logoimg img {
      width: 13vw;
    }
    .adt,
    .adt2,
    .adt23 {
      font-size: 16px;
    }
  }
  @media (max-width: 490px) {
    .logoimg img {
      display: none;
    }
    .adt,
    .adt2,
    .adt23 {
      font-size: 16px;
    }
  }
  @media (max-width: 375px) {
    .adt,
    .adt2,
    .adt23 {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .mobile-nav {
    display: block;
  }
  .sideNavbar {
    display: none !important;
  }
  .admin-offcanvas-body,
  .admin-offcanvas-header {
    background-color: #0b0b27;
  }
  .admin-logo {
    margin-bottom: 20px;
  }
  .user_slide {
    width: 0vw;
  }
}
