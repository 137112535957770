h2 {
  font-family: Bungee, Regular;
}
.section-title {
  font-size: 40px;
  line-height: 52px;
  font-family: "Bungee";
  text-transform: capitalize;
  margin-bottom: 45px;
  margin-top: 1px;
}
.section-title .homepage-blue-platform-text{
  font-size: 40px;
  line-height: 52px;
  font-family: "Bungee";
  text-transform: capitalize;
  margin-bottom: 45px;
  color: #1DCADC;
}
.desc-para {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin: auto;
  text-align: center;
}
.desc-para:not(:last-child) {
  margin-bottom: 20px;
}
.desc-download-para {
  font-size: 18px !important;
}

.max-w-750{
  max-width: 750px;
}
/* card main start */
.card-section {
  background-color: #131128;
}
.card-cont h2 {
  color: #fff;
  font-family: Audiowide, Regular;
  font-size: 30px;
  line-height: 52px;
  margin-left: 0px;
}
.card-img {
  width: 100%;
  height: 100%;
}
.card-main {
  /* display: flex; */
  align-items: center;
  height: 100vh;
  margin-top: 40px;
}
.card-cont p {
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}

.hero-btn {
  font-size: 24px !important;
  background: var(--Grrr, linear-gradient(245deg, #1DCADC 0%, #582C7F 100%));
  padding: 9px 32px;
  /* border-radius: 25px; */
  border: 1px solid #4b6ebc;
  color: #fff;
  text-transform: capitalize;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  font-family: 'Bungee';
}

.hero-btn1{
 font-size: 24px !important;

border-radius: 5px;
padding: 9px 32px 9px 32px;
margin-right: 10px;
background-color: #0e0a27;
border: 1px solid #fff;
text-transform: capitalize;
line-height: 24px;
}

.hvr-grow{
  position: relative;
  left: -180px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 0px 44px 10px rgba(0, 102, 255, 0.45);
  border-radius: 30px;
}

.hvr-grow1{
  position: relative;
  top: -80px;
  left: -40px;
  border-radius: 30px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 0px 44px 10px rgba(255, 0, 0, 0.45);
}

.img{
  margin-top: 20px;
}

.buy-now {
  font-size: 16px;
  background: linear-gradient(to bottom, #4b6ebc, #2d4f8c);
  padding: 12px 24px;
  border-radius: 40px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.buy-now:hover {
  background-color: #fff;
  color: #4b6ebc;
  border: 2px solid #4b6ebc;
  cursor: pointer;
  transform: scale(1.1);
}
.btn-main {
  padding-top: 25px;
}
/* card main end */

/* work  its work start */
.video-section {
  padding-top: 40px;
}
iframe {
  border: 0;
  border-radius: 16px;
  width: 100%;
}

.work-section {
  background-color: #131128;
  padding-top: 75px;
}
/* .section-title {
  text-align: center;
  color: #fff;
} */
/* iframe.embed-responsive-item {
    max-width: 100% !important;
    width: 100% !important;
    height: 700px;
    border-radius: 16px;
} */

.ytp-cued-thumbnail-overlay-image {
  background: url(../images/home-img-shape.png) !important;
  height: 100vh;
}
/* .video-section{
    background-image: url(../images/banner.png) !important;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    border-radius: 20px;
} */

/* buy start */
.buy-section {
  background-color: #131128;
  padding: 75px 0px;
}
.buy-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  color: #fff;
  grid-gap: 25px;
  padding-top: 3rem;
}
.buy-ct h3 {
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
}
.buy-img {
  padding: 15px 0px;
}
.buy-ct p {
  font-size: 14px;
  line-height: 24px;
  max-width: 252px;
  margin: auto;
}
.btn-buy {
  text-align: center;
  padding-top: 25px;
}

/* market start */
.marketpl-sec {
  background-color: #131128;
  padding: 75px 0px;
}
.marketpl-sec {
  background-image: url(../images/Background-market.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.markt-cont h2 {
  font-size: 40px;
  line-height: 41px;
  color: #fff;
  font-family: Audiowide, Regular;
}
.mart-ct h3 {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-transform: capitalize;
}
.mart-ct p {
  padding: 15px 0px;
}
.mart-ct p {
  font-size: 14px;
  color: #fff;
  line-height: 26px;
}
.mt-main {
  align-items: center;
  padding-top: 25px;
}
.mt-main img {
  width: 100%;
  max-width: 60%;
}
.mt-main img {
  max-width: 60%;
}

.market-item1:nth-child(2) .mart-ct {
  /* text-align: right; */
}
.market-item1:nth-child(2) .mart-img2 {
  text-align: right;
}

.market-item1:nth-child(2) .markt-img img {
  margin-top: -40px;
  width: 100%;
}

.search-bar-container {
  display: flex;
  justify-content: center !important;
  margin-top: 30px;
}

.search-bar .input-group {
  justify-content: center;
  background-color: #282454 !important;
  border-radius: 5px;
}

/* chaallange start */

.Challenge-sec {
  background-image: url(../images/startChangelbg1.png);
  background-repeat: no-repeat;
  padding: 90px 0px 45px;
  background-position:center;
  background-size: cover;
}
.search-bar .form-control {
  color: #fff;
}

.search-bar .input-group.md-form.form-sm.form-1 input {
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.search-bar .input-group.md-form.form-sm.form-2 input {
  border: 1px solid #bdbdbd;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.search-bar .input-group.md-form.form-sm.form-2 input.red-border {
  border: 1px solid #282454;
  background-color: #282454;
  border-radius: 25px;
  font-size: 15px;
}
.input-group.md-form.form-sm.form-2 input.lime-border {
  border: 1px solid #cddc39;
}
.search-bar .input-group.md-form.form-sm.form-2 input.amber-border {
  border: 1px solid #ffca28;
}
.search-bar {
  width: 330px;
  margin-bottom: 10px;
  /* padding-top: 20px; */
}

.search-bar .form-control::placeholder {
  color: #fff !important;
  opacity: 1;
  font-size: 14px;
  line-height: 24px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #282454;
  border: 1px solid #282454;
  border-radius: 25px;
}
.input-group {
  padding: 6px;
}

/* tab start */
.tab-cont p {
  color: #fff;
}
.tab-btn {
  background-color: #282454 !important;
  border-radius: 16px;
  padding: 4px 20px;
}
.tab-btn {
  background: #282454 !important;
  border-radius: 16px !important;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
.tab-btn .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #4a6bbc !important;
  border-color: #4a6bbc;
  border-radius: 25px;
  color: #fff !important;
}
.tab-btn .nav-tabs .nav-link:hover {
  border-color: none !important;
}
.tab-section {
  padding-top: 30px;
}
.nav-tabs {
  border-bottom: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  text-align: -webkit-center;
}
.nav-tabs:nth-child(2) {
  border-bottom: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: -webkit-center;
  padding: 22px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #282254;
  isolation: isolate;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 5px 40px;
}
.btn-tb2 {
  text-align: center;
  padding-top: 30px;
}

/* rule start */
.rule-ct h3 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
.rule-ct p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}
.rule-sec {
  background-image: url(../images/rule-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #131128;
  padding: 75px 0px;
}
.rule-main1 {
  padding-top: 20px;
}
.rule-img {
  text-align: center;
}
.rule-img img {
  width: 100%;
}

.rule-img p {
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #FFFFFF;
}

.rule-main2 .rule-img2 {
  text-align: right;
}
.rule-main2 {
  margin-top: -50px;
  align-items: center;
}
.rule-ct {
  padding-left: 35px;
  text-align: justify;
}

.ruleimg {
  text-align: right;
}

.rule-img2 {
  background-image: url(../images/rulebg-2.png);
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 99999 !important;
}

/* info start */

.info-sec {
  background-color: #131128;
  padding: 75px 0px;
}
.inf-ct h4 {
  font-size: 40px;
  line-height: 51px;
  color: #fff;
  font-family: Audiowide, Regular;
}
.inf-ct p {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

/* .info-main {
    background-image: url(../images/NFT-img.png);
    background-repeat: no-repeat;
    background-position: right;
    z-index: 99999 !important;
    background-size: auto;
} */
.nft-img img {
  margin-top: -164px;
}
.info-main {
  align-items: right;
}
.rule-img3 {
  background-image: url(../images/rule-bg4.png);
  background-repeat: no-repeat;
  background-position: unset;
  z-index: 99999 !important;
  height: 300px;
  text-align: right;
}
.inf-ct {
  padding-right: 25px;
}
.icon-mob {
  display: none;
}
.icon-mob img {
  width: 100%;
}
.form-control::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
  font-size: 14px;
  line-height: 24px;
}
.card-cont h2{
  text-align: left;
}
/* responsive */
@media only screen and (max-width: 1200px) {
  iframe {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1024.98px) {
  .card-img img{
    max-width: 300px;
  }
}
@media only screen and (max-width: 991.98px) {
  .buy-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  .nav-tabs {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .nav-tabs:nth-child(2) {
    border-bottom: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: -webkit-center;
    padding: 22px;
    grid-gap: 20px;
  }
  .nav-tabs {
    grid-gap: 0px;
  }
  .nav-tabs:nth-child(2) {
    border-bottom: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: -webkit-center;
    padding: 0px;
    grid-gap: 0px;
  }

  .tabct-main {
    align-items: center;
  }
  .rule-main2 {
    margin-top: -14px;
    align-items: center;
  }
  .marketpl-sec {
    padding: 40px 0px;
  }
  .Challenge-sec {
    padding: 50px 0px;
  }
  .rule-sec {
    padding: 50px 0px;
  }
  .info-sec {
    padding: 70px 0px;
  }
  iframe {
    max-width: 100%;
    height: 400px;
  }
  .nft-img img {
    margin-top: -63px;
  }
  .inf-ct h4 {
    font-size: 35px;
    line-height: 51px;
  }
  .info-main {
    text-align: left;
    align-items: baseline;
    flex-direction: initial;
  }
  .card-main{
    height: auto;
    margin-top: 110px;
    /* flex-direction: column; */
  }
  .order1{
    order: 1;
  }
  .order2{
    order: 2;
  }
  .card-section {
    padding-top: 9rem;
    padding-bottom: 5rem;
    margin-top: 0;
  }
  .hvr-grow1{
    /* margin-top: -222px !important; */
    top: 0;
    /* width: 65%; */
    left: -30%;
  }
  .hvr-grow{
    /* width: 65%; */
    top: -140px;
  }
  .card-cont h2{
    text-align: center;
  }
  .btn-main{
    text-align: center !important;
  }
  .card-img{
    margin-bottom: 30px;
  }
  .founder-top{
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 778.98px) {
  .buy-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .card-cont {
    text-align: center;
  }
  .card-section {
    /* padding-top: 2rem; */
    padding-bottom: 2rem;
    margin-top: 0px;
    background-position: center;
  }
  .work-section {
    padding: 30px 0px;
  }
  .buy-section {
    padding: 31px 0px;
  }
  .foot-list ul.footer-menu {
    display: block;
    /* flex-direction: column; */
    flex-wrap: wrap;
    padding: 0px;
  }
  ul.footer-menu li {
    padding: 4px 0px;
  }
  .nav-tabs:nth-child(2) {
    border-bottom: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: -webkit-center;
    padding: 0px;
    grid-gap: 0px;
  }
  /* .card-main {
    height: 100%;
    flex-direction: column-reverse;
  } */
  .hvr-grow {
    left: 10%;
  }
  .hvr-grow1 {
    right: unset;
    left: -10%;
  }
  .card-img {
    text-align: center;
  }
  .marketpl-sec {
    padding: 30px 0px;
  }
  .buy-img img {
    width: 85%;
  }
  .buy-ct h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .markt-cont {
    padding-left: 0px;
    text-align: center;
  }
  .Challenge-sec {
    padding: 30px 0px;
  }
  .nav-tabs:nth-child(2) {
    grid-template-columns: repeat(3, 1fr);
  }
  .buy-img img {
    width: 60%;
    height: 350px;
  }

  .market-item1:nth-child(2) .mart-ct {
    text-align: center;
    padding: 20px 0px;
  }
  .market-item1:nth-child(2) .mart-img2 {
    text-align: center;
  }
  .rule-main2 {
    margin-top: 0px;
    align-items: center;
    text-align: center;
  }
  .markt-img {
    text-align: center;
  }
  .mt-main img {
    max-width: 51%;
    padding-top: 20px;
    height: 400px;
  }
  .market-item1:nth-child(2) .markt-img img {
    margin-top: 0px;
    width: 51%;
    height: 400px;
  }
  .rule-img img {
    width: 95%;
  }
  .rule-ct {
    padding-left: 0px !important;
  }
  .rule-main2 .rule-img2 {
    padding-top: 20px;
  }
  .info-sec {
    padding: 0px 0px 50px 0px;
  }
  .nft-img img {
    margin-top: 0px;
  }
  .info-main {
    text-align: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .rule-sec {
    background-color: #131128;
    padding: 30px 0px;
  }
  .rule-main2 {
    margin-top: -14px;
    align-items: center;
    text-align: left;
  }
  .tab-right {
    padding-top: 20px;
  }
  .rule-img3 {
    background-position: center;
    /* z-index: 99999 !important; */
    height: 300px;
    text-align: right;
    background-size: contain;
  }
  .icon-mob {
    display: block;
    text-align: center;
  }
  .rule-img3 {
    background-image: none;
  }
  .nft-img img {
    display: none;
  }
  .mt-main img {
    height: 350px;
  }
  .mt-main {
    padding-top: 0px;
  }
  .ruleimg {
    text-align: center;
  }
  .info-main {
    text-align: left;
    align-items: baseline;
    flex-direction: initial;
  }
  /* .hvr-grow{
    width: 85%;
    left: -85px;
  }
  .hvr-grow1{
    width: 85%;
  }
  .card-cont h2{
    text-align: center !important;
  }
  .hero-btn1{
    margin-bottom: 20px ;
  } */
}

@media only screen and (max-width: 667px) {
  .buy-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .nav-tabs {
    grid-template-columns: repeat(2, 1fr);
  }
  .section-title h2 {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .section-title p {
    font-size: 13px !important;
    line-height: 22px;
  }
  .card-cont h2 {
    font-size: 30px;
    line-height: 42px;
  }
  .card-cont p {
    font-size: 12.5px;
    line-height: 22px;
  }
  .mart-ct h3 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 25px;
  }
  .rule-ct p {
    font-size: 12.5px;
    line-height: 22px;
  }
  .nav-tabs:nth-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }
  .inf-ct {
    padding-right: 0px;
  }
  .rule-main1 {
    text-align: center;
  }
  .markt-cont h2 {
    font-size: 30px !important;
    line-height: 45px;
    margin-top: 25px;
  }
  .mart-ct p {
    font-size: 13px;
    line-height: 20px;
  }
  iframe {
    max-width: 100%;
    height: 350px;
  }
  .mart-ct p {
    padding: 0px;
  }

  .rule-img2 {
    background-repeat: no-repeat;
    background-position: bottom;
    /* z-index: 99999 !important; */
    background-size: auto;
  }
  .rule-ct {
    text-align: center;
    padding: 20px 0px;
  }

  .nft-img img {
    margin-top: 0px;
    height: 310px;
  }
  .rule-img3 {
    background-position: left;
    /* z-index: 99999 !important; */
    height: 300px;
    text-align: right;
    background-size: contain;
  }
  .inf-ct h4 {
    font-size: 35px;
    line-height: 51px;
  }
  .icon-mob img {
    height: 350px;
    max-width: 75%;
  }
  .info-main {
    text-align: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .inf-ct {
    padding-right: 0px;
    padding-top: 80px;
  }
}

@media only screen and (max-width: 568px) {
  .nav-tabs {
    grid-template-columns: repeat(1, 1fr);
  }
  .nav-tabs:nth-child(2) {
    grid-template-columns: repeat(1, 1fr);
  }
  .duelchalenge-sec .nav-tabs .nav-link {
    padding: 5px 40px;
    width: 100%;
  }
  .challenge-list {
    padding: 20px 0px;
  }
  .duelchalenge-sec {
    padding: 0px;
  }
  .rule-img2 {
    background-repeat: no-repeat;
    background-position: left;
    /* z-index: 99999 !important; */
    background-size: auto;
  }
  .market-item1:nth-child(2) .markt-img img {
    width: 74%;
    height: 350px;
  }
  .hvr-grow{
    width: 100%;
    max-width: 400px;
    margin-left: unset;
    left: -20;
    top: -60px
 
  }
  .hvr-grow1{
    max-width: 400px;
    width: 100%;
   left: 60px;
   top: 60px;
   
  }
  .card-img:before{
    top:10px
  }
  .card-cont h2{
    /* margin-left: 60px; */
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .card-cont {
    justify-content: center;
  }
  .work-section{
    padding-top: 0px
  }
  .section-title p{
    max-width: 600px;
    
  }

}

@media (max-width: 576.98px) {
  .hvr-grow {
    left: -50px;
    top: 30px;
  }
  .hvr-grow1 {
    right: unset;
    left: 50px;
    top: -30px;
  }
  .card-main {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .search-bar-container .search-bar .input-group {
    width: 100%;
  }
  .search-bar-container-admin .search-bar .input-group {
    width: 240px !important;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }
 
}
@media (max-width:396.98px){
  .card-img img{
    width: 70%;
  }
}

@media (max-width:395.98px){
  .hero-btn1{
    margin-bottom: 20px;
    width: 100%;
  }
  .hero-btn{
   width: 100%; 
  }
  .contact-btn{
    width: 100% !important;
  }
 
  /* .card-cont p{
    padding-right: 20px;
  }
  .btn-main{
    padding-right: 25px;
  }
  .section-title p{
    padding-right: 20px;
  }
  .Challenge-sec .section-title h2{
    padding-right: 20px;
  }
  .btn-tb2 .hero-btn{
   font-size: 20px !important;
   margin-right: 20px !important ;
   width: 91%;
  } */
}
@media (max-width:390.98px){
  .card-img img{
    max-width: 200px;
  }
  .hvr-grow {
      left: -60px;
  }
  .hvr-grow1 {
      right: 30px;
  }
  .card-img:before {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-size: contain;
    background-position: center;
}
  .card-cont h2{
    margin-left: 0;
  }
  .hero-btn1{
    margin-bottom: 20px;
    width: 100%;
  }
  .hero-btn{
  width: 100%; 
  }
  .contact-btn{
    width: 100% !important;
  }
}
@media (max-width:820.98px){
  .hvr-grow{
    left: -70px;
  }
  .card-img:before{
    height: 120%;
    
  }
}


/* add new css on 22-06-23 */
.Challenge-sec .btn{
  margin-top: 10px;
}
.hero-banner .title{
  color: #fff;
  font-family: "Bungee";
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 10px;
}
.hero-banner .desc-para{
  max-width: 496px;
  margin-bottom: 20px;
}
.banner-btn{
  /* display: flex;
  gap: 20px;
  margin-top: 10px; */
}
/* .hero-banner {
  background-image: url(../images/HeroBackgroundImage.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 845px;
  padding-top: 253px;
} */
.hero-banner {
  position: relative;
  background-image: url(../images/HeroBackgroundImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 845px;
  padding-top: 253px;
  overflow: hidden; /* Ensure children don't overflow the container */
}
.banner-image-wrapper{
  position: relative;
}
.banner-image-wrapper img{
  border-radius: 30px;
  max-width: 400px;
  width: 100%;
  max-height:300px;
  height: 100%;
}
.banner-image-wrapper .img1{
  box-shadow: 0px 0px 44px 10px #0066FF73;
  position: relative;
  top: 67px;
  z-index: 99;
  left: 20px;
}
.banner-image-wrapper .img2{
  box-shadow: 0px 0px 44px 10px #FF000073;
  text-align: end;
  position: relative;
  z-index: 99;
  right: 0;
  float: right;
  top: -20px;
}

.banner-image-wrapper:before {
  content: '';
  background-image: url("../../src/images/z-icon.png");
  max-width: 380px;
  width: 100%;
  height: 648px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 9;
}
.hero-banner .row{
  align-items: center;
}
@media(max-width:991.98px){
  .hero-banner .title{
    text-align: center;
  }
  .hero-banner .desc-para {
    max-width: 100%;
    text-align: center;
  }
  .banner-btn{
    justify-content: center;
  }
  .banner-image-wrapper{
    max-width: 648px;
    width: 100%;
    margin: 50px auto;
  }
  .hero-banner {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media(max-width:576.98px){
  .banner-image-wrapper img{
    max-width: 290px;
  }
  .banner-image-wrapper {
    max-width: 400px;
    width: 100%;
  }
  .banner-image-wrapper:before {
    height: 500px;
  }
  .banner-image-wrapper .img2 {
    top: 0px;
    right: 10px;
  }
  .banner-image-wrapper .img1 {
    top: 57px;
  }
}
@media(max-width:480.98px){
  .banner-btn{
    flex-direction: column;
  }
}

.rules-infographic{
  max-width: 1061px;
}
.rules-feature-bg{
  background-image: url(../images/rules-feature-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.primary-btn-linear-bg{
  background: var(--Grrr, linear-gradient(245deg, #1DCADC 0%, #582C7F 100%));
}

.profit-text-center{
  text-align: center;
}
.device-img-top{
  margin-top: 65px;
}
.founders-images{
  width: 340px; /* Set a fixed width */
  height: 450px; 
}
.profit-sec-center{
  justify-content: center;
}
.public-chal-btn-top{
 margin-top: 50px;
}
.founder-top{
  margin-top: 110px;
}
.platform-sec{
  margin-top: 52px;
}