.contact-sec{
    background-color: #121027;
    padding: 40px 0px;
}
.cont-form {
    max-width: 867px;
    margin:auto;
    padding: 30px;
    background: #282454;
    border-radius: 20px;
}
.form-label {
    margin-bottom: 0.5rem;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
}
.form-control::placeholder {
    color: #131128 !important;
    opacity: 1; /* Firefox */
    font-size: 14px;
    line-height: 24px;
  }
  .contact-form{
    padding:10px;
    background-color: #fff !important;
  }
  .cont-form .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: snow;
    outline: 0;
    box-shadow: none;
}
  .cont-subt .hero-btn {
    background-color: rgb(75, 110, 188);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(75, 110, 188);
    border-image: initial;
    border-radius: 25px;
    padding: 6px 24px;
}
.cont-subt {
    text-align: center;
    padding-top: 20px;
}

/* responsive */
@media only screen and (max-width:1024px) {
    .contf {
        padding: 0px 10px;
    }
}

@media only screen and (max-width:777px) {
    .cont-form {
        padding: 15px;
    }
}

